export const timeZonesList = [
	{ value: 'Etc/UTC', text: 'UTC' },
	{ value: 'Pacific/Midway', text: 'International Date Line West / Midway Island' },
	{ value: 'Pacific/Pago_Pago', text: 'American Samoa' },
	{ value: 'Pacific/Honolulu', text: 'Hawaii' },
	{ value: 'America/Juneau', text: 'Alaska' },
	{ value: 'America/Los_Angeles', text: 'Pacific Time (US & Canada)' },
	{ value: 'America/Tijuana', text: 'Tijuana' },
	{ value: 'America/Denver', text: 'Mountain Time (US & Canada)' },
	{ value: 'America/Phoenix', text: 'Arizona' },
	{ value: 'America/Chihuahua', text: 'Chihuahua' },
	{ value: 'America/Mazatlan', text: 'Mazatlan' },
	{ value: 'America/Chicago', text: 'Central Time (US & Canada)' },
	{ value: 'America/Regina', text: 'Saskatchewan' },
	{ value: 'America/Mexico_City', text: 'Mexico City / Guadalajara' },
	{ value: 'America/Monterrey', text: 'Monterrey' },
	{ value: 'America/Guatemala', text: 'Central America' },
	{ value: 'America/New_York', text: 'Eastern Time (US & Canada)' },
	{ value: 'America/Indiana/Indianapolis', text: 'Indiana (East)' },
	{ value: 'America/Bogota', text: 'Bogota' },
	{ value: 'America/Lima', text: 'Lima / Quito' },
	{ value: 'America/Halifax', text: 'Atlantic Time (Canada)' },
	{ value: 'America/Caracas', text: 'Caracas' },
	{ value: 'America/La_Paz', text: 'La Paz' },
	{ value: 'America/Santiago', text: 'Santiago' },
	{ value: 'America/St_Johns', text: 'Newfoundland' },
	{ value: 'America/Sao_Paulo', text: 'Brasilia' },
	{ value: 'America/Argentina/Buenos_Aires', text: 'Buenos Aires' },
	{ value: 'America/Montevideo', text: 'Montevideo' },
	{ value: 'America/Guyana', text: 'Georgetown' },
	{ value: 'America/Godthab', text: 'Greenland' },
	{ value: 'Atlantic/South_Georgia', text: 'Mid-Atlantic' },
	{ value: 'Atlantic/Azores', text: 'Azores' },
	{ value: 'Atlantic/Cape_Verde', text: 'Cape Verde Is.' },
	{ value: 'Europe/Dublin', text: 'Dublin' },
	{ value: 'Europe/Lisbon', text: 'Lisbon' },
	{ value: 'Europe/London', text: 'London / Edinburgh' },
	{ value: 'Africa/Casablanca', text: 'Casablanca' },
	{ value: 'Africa/Monrovia', text: 'Monrovia' },
	{ value: 'Europe/Belgrade', text: 'Belgrade' },
	{ value: 'Europe/Bratislava', text: 'Bratislava' },
	{ value: 'Europe/Budapest', text: 'Budapest' },
	{ value: 'Europe/Ljubljana', text: 'Ljubljana' },
	{ value: 'Europe/Prague', text: 'Prague' },
	{ value: 'Europe/Sarajevo', text: 'Sarajevo' },
	{ value: 'Europe/Skopje', text: 'Skopje' },
	{ value: 'Europe/Warsaw', text: 'Warsaw' },
	{ value: 'Europe/Zagreb', text: 'Zagreb' },
	{ value: 'Europe/Brussels', text: 'Brussels' },
	{ value: 'Europe/Copenhagen', text: 'Copenhagen' },
	{ value: 'Europe/Madrid', text: 'Madrid' },
	{ value: 'Europe/Paris', text: 'Paris' },
	{ value: 'Europe/Amsterdam', text: 'Amsterdam' },
	{ value: 'Europe/Berlin', text: 'Berlin / Bern' },
	{ value: 'Europe/Rome', text: 'Rome' },
	{ value: 'Europe/Stockholm', text: 'Stockholm' },
	{ value: 'Europe/Vienna', text: 'Vienna' },
	{ value: 'Africa/Algiers', text: 'West Central Africa' },
	{ value: 'Europe/Bucharest', text: 'Bucharest' },
	{ value: 'Africa/Cairo', text: 'Cairo' },
	{ value: 'Europe/Helsinki', text: 'Helsinki' },
	{ value: 'Europe/Kiev', text: 'Kyiv' },
	{ value: 'Europe/Riga', text: 'Riga' },
	{ value: 'Europe/Sofia', text: 'Sofia' },
	{ value: 'Europe/Tallinn', text: 'Tallinn' },
	{ value: 'Europe/Vilnius', text: 'Vilnius' },
	{ value: 'Europe/Athens', text: 'Athens' },
	{ value: 'Europe/Istanbul', text: 'Istanbul' },
	{ value: 'Europe/Minsk', text: 'Minsk' },
	{ value: 'Asia/Jerusalem', text: 'Jerusalem' },
	{ value: 'Africa/Harare', text: 'Harare' },
	{ value: 'Africa/Johannesburg', text: 'Pretoria' },
	{ value: 'Europe/Moscow', text: 'Moscow / St. Petersburg / Volgograd' },
	{ value: 'Asia/Kuwait', text: 'Kuwait' },
	{ value: 'Asia/Riyadh', text: 'Riyadh' },
	{ value: 'Africa/Nairobi', text: 'Nairobi' },
	{ value: 'Asia/Baghdad', text: 'Baghdad' },
	{ value: 'Asia/Tehran', text: 'Tehran' },
	{ value: 'Asia/Muscat', text: 'Muscat / Abu Dhabi' },
	{ value: 'Asia/Baku', text: 'Baku' },
	{ value: 'Asia/Tbilisi', text: 'Tbilisi' },
	{ value: 'Asia/Yerevan', text: 'Yerevan' },
	{ value: 'Asia/Kabul', text: 'Kabul' },
	{ value: 'Asia/Yekaterinburg', text: 'Ekaterinburg' },
	{ value: 'Asia/Karachi', text: 'Karachi / Islamabad' },
	{ value: 'Asia/Tashkent', text: 'Tashkent' },
	{ value: 'Asia/Kolkata', text: 'Kolkata / New Delhi / Chennai / Mumbai' },
	{ value: 'Asia/Kathmandu', text: 'Kathmandu' },
	{ value: 'Asia/Astana', text: 'Astana' },
	{ value: 'Asia/Dhaka', text: 'Dhaka' },
	{ value: 'Asia/Colombo', text: 'Sri Jayawardenepura' },
	{ value: 'Asia/Almaty', text: 'Almaty' },
	{ value: 'Asia/Novosibirsk', text: 'Novosibirsk' },
	{ value: 'Asia/Rangoon', text: 'Rangoon' },
	{ value: 'Asia/Bangkok', text: 'Bangkok / Hanoi' },
	{ value: 'Asia/Jakarta', text: 'Jakarta' },
	{ value: 'Asia/Krasnoyarsk', text: 'Krasnoyarsk' },
	{ value: 'Asia/Shanghai', text: 'Beijing' },
	{ value: 'Asia/Chongqing', text: 'Chongqing' },
	{ value: 'Asia/Hong_Kong', text: 'Hong Kong' },
	{ value: 'Asia/Urumqi', text: 'Urumqi' },
	{ value: 'Asia/Kuala_Lumpur', text: 'Kuala Lumpur' },
	{ value: 'Asia/Singapore', text: 'Singapore' },
	{ value: 'Asia/Taipei', text: 'Taipei' },
	{ value: 'Australia/Perth', text: 'Perth' },
	{ value: 'Asia/Irkutsk', text: 'Irkutsk' },
	{ value: 'Asia/Ulaanbaatar', text: 'Ulaanbaatar' },
	{ value: 'Asia/Seoul', text: 'Seoul' },
	{ value: 'Asia/Tokyo', text: 'Tokyo / Sapporo / Osaka' },
	{ value: 'Asia/Yakutsk', text: 'Yakutsk' },
	{ value: 'Australia/Darwin', text: 'Darwin' },
	{ value: 'Australia/Adelaide', text: 'Adelaide' },
	{ value: 'Australia/Canberra', text: 'Canberra' },
	{ value: 'Australia/Melbourne', text: 'Melbourne' },
	{ value: 'Australia/Sydney', text: 'Sydney' },
	{ value: 'Australia/Brisbane', text: 'Brisbane' },
	{ value: 'Australia/Hobart', text: 'Hobart' },
	{ value: 'Asia/Vladivostok', text: 'Vladivostok' },
	{ value: 'Pacific/Guam', text: 'Guam' },
	{ value: 'Pacific/Port_Moresby', text: 'Port Moresby' },
	{ value: 'Asia/Magadan', text: 'Magadan' },
	{ value: 'Pacific/Guadalcanal', text: 'Solomon Is.' },
	{ value: 'Pacific/Noumea', text: 'New Caledonia' },
	{ value: 'Pacific/Fiji', text: 'Fiji' },
	{ value: 'Asia/Kamchatka', text: 'Kamchatka' },
	{ value: 'Pacific/Majuro', text: 'Marshall Is.' },
	{ value: 'Pacific/Auckland', text: 'Wellington / Auckland' },
	{ value: 'Pacific/Tongatapu', text: "Nuku'alofa" },
	{ value: 'Pacific/Fakaofo', text: 'Tokelau Is.' },
	{ value: 'Pacific/Chatham', text: 'Chatham Is.' },
	{ value: 'Pacific/Apia', text: 'Samoa' },
	{ value: 'Africa/Kigali', text: 'Rwanda' }
];
