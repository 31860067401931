/* eslint-disable angular/di-unused */
'use strict';

angular
	.module('bringgApp')
	.service(
		'TasksGrouping',
		function (
			TranslationService,
			Teams,
			Employees,
			localStorageService,
			DispatchListService,
			TimeManagerService,
			PRIVILEGES_TYPES,
			Authentication,
			LoadConstraintsService,
			MerchantConfigurations,
			TimezoneService,
			RunsService,
			Fleets,
			FleetChecker,
			AssignDriverValidations,
			CollapsedLinkedTasksService
		) {
			var _this = this;
			_this.groupBy = groupBy;
			_this.getIconWithDetailsHtml = getIconWithDetailsHtml;

			var _noTeamName = TranslationService.instant('DISPATCH_LIST.NO_TEAM');
			var _unassigned = TranslationService.instant('BIG_MAP.UNASSIGNED');
			var _noRouteName = TranslationService.instant('DISPATCH_LIST.NO_ROUTE');
			var _noFleetName = TranslationService.instant('DISPATCH_LIST.NO_FLEET');
			var borderHtml = '<span class="orders-border">/</span>';

			const GROUP_TYPES = {
				Team: 'Team',
				TeamDriver: 'TeamDriver',
				TeamRoute: 'TeamRoute',
				Driver: 'Driver',
				Route: 'Route',
				Fleet: 'Fleet',
				FleetTeam: 'FleetTeam'
			};

			_this.GROUP_TYPES = GROUP_TYPES;

			function groupBy(item, data, params, extraDisplayData) {
				const buildTasksResult = CollapsedLinkedTasksService.buildTasks(item, data.tasks ?? [], params.origin);
				if (buildTasksResult) {
					data.tasks = buildTasksResult;
					params.updateTasks(data.tasks);
				}
				extraDisplayData = extraDisplayData || {};
				if (item.value === GROUP_TYPES.Team) {
					return groupByTeam(data, params, true);
				} else if (item.value === GROUP_TYPES.Driver) {
					return groupByDriver(
						data,
						params,
						false,
						function (group) {
							return filterDriverGroups(params.showEmptyDrivers, group);
						},
						params.showEmptyDrivers ? _.map(data.employees, 'id') : undefined,
						undefined,
						extraDisplayData[GROUP_TYPES.Driver]
					);
				} else if (item.value === GROUP_TYPES.TeamDriver) {
					return [
						groupByTeam(data, params, true),
						groupByDriver(
							data,
							params,
							true,
							function (employeeGroup, teamGroup) {
								return filterDriverGroups(params.showEmptyDrivers, employeeGroup, teamGroup);
							},
							params.showEmptyDrivers ? _.map(data.employees, 'id') : undefined,
							'team_id',
							extraDisplayData[GROUP_TYPES.Driver]
						)
					];
				} else if (item.value === GROUP_TYPES.Route) {
					return groupByRoute(data, params, false, undefined, extraDisplayData[GROUP_TYPES.Route]);
				} else if (item.value === GROUP_TYPES.TeamRoute) {
					return [
						groupByTeam(data, params, false),
						groupByRoute(data, params, true, 'team_id', extraDisplayData[GROUP_TYPES.Route])
					];
				} else if (item.value === GROUP_TYPES.Fleet) {
					return groupByFleet(data);
				} else if (item.value === GROUP_TYPES.FleetTeam) {
					return [groupByFleet(data), groupByTeam(data, params, undefined, undefined, true)];
				} else if (item.value === 'None') {
					return null;
				}
			}

			function groupByFleet(data) {
				return {
					getter: 'fleet_id',
					formatter: function (group) {
						const fleetId = group.value;
						const selectAllCheckbox = getSelectAllCheckbox(
							[{ fieldName: 'fleet_id', value: fleetId }],
							group,
							data.selectedRows
						);
						const fleetName = fleetId ? Fleets.getName(fleetId) : _noFleetName;

						let result =
							selectAllCheckbox +
							' <div class="group-title"> <div class="group-title-name ">' +
							fleetName +
							'</div>';

						result += getOrdersHtml(group.rows.length, 0, false);

						return (result += '</div></div>');
					},
					comparer: groupByFleetsComparer
				};
			}

			function groupByDriver(
				data,
				params,
				isInner,
				filterPredefinedValues,
				predefinedValues,
				extraGroupFieldName,
				extraDisplayDataFunc
			) {
				return {
					getter: 'user_id',
					formatter: function (group) {
						var userId = group.value;
						var driver = Employees.employeeByIdMap[userId];
						var driverName = _.get(driver, 'name', TranslationService.instant('BIG_MAP.UNASSIGNED'));
						const selectAllCheckbox = getSelectAllCheckbox(
							[{ fieldName: 'user_id', value: userId }],
							group,
							data.selectedRows,
							extraGroupFieldName
						);

						var innerStyle = isInner ? 'style="margin-left: 10px;"' : '';

						var userResult =
							selectAllCheckbox +
							' <div class="group-title" ' +
							innerStyle +
							`> <div class="group-title-name ${innerStyle ? 'inner-title' : ''}">` +
							driverName +
							'</div>';

						userResult += getOrdersHtml(group.rows.length, 0, false);
						userResult += '<span class="details-container details-text">';
						driver?.status === 'offline'
							? (userResult += getIcon('offline-icon', ''))
							: (userResult += '<div class="empty-icon"></div>');

						userResult += '</span>';

						if (group.rows.length > 0 || params.showEmptyDrivers) {
							innerStyle = isInner ? 'style="right:89px;"' : '';

							var extraData = extraDisplayDataFunc ? extraDisplayDataFunc(group) : '';

							if (params.origin === 'dispatch' && params.realtimeEtaEnabled && driver?.eta_to_home) {
								if (
									(params.hideEtaToHomeForBusyDrivers && Employees.isFree(driver)) ||
									!params.hideEtaToHomeForBusyDrivers
								) {
									userResult += getTimeHtml('PLANNING.ETA_TO_BASE', driver.eta_to_home);
								}
							}

							if (!params.hideNeedsToLeaveByForDrivers && userId) {
								userResult += hasToLeave(group.rows);
							}

							userResult +=
								extraData + '<div class="window-progress-ingrid-container"' + innerStyle + ' >';
						}

						return userResult + '</div></div>';
					},
					filterPredefinedValues: filterPredefinedValues,
					predefinedValues: predefinedValues,
					comparer: groupByUsersComparer
				};
			}

			function getSkillsString(skillsArr) {
				var MAX_SKILLS_TO_VIEW = 3;
				if (_.isEmpty(skillsArr)) {
					return '';
				}

				var result =
					'<span class="skills" data-test-id="skills">(' + skillsArr.slice(0, MAX_SKILLS_TO_VIEW).join(', ');

				if (skillsArr.length > MAX_SKILLS_TO_VIEW) {
					result +=
						' <span class="color-like-a-link plus-sign">' +
						(skillsArr.length - MAX_SKILLS_TO_VIEW) +
						'</span>';
				}
				return result + ')</span>';
			}

			function groupByRoute(data, params, isInner, extraGroupFieldName, extraDisplayDataFunc) {
				return {
					getter: 'run_id',
					formatter: function (group) {
						var driver;
						var value = _noRouteName;
						var firstTask = group.rows[0];
						var teamIds = firstTask.team_ids;
						var run = RunsService.getRunFromTask(firstTask);

						var trailersIds = _.chain(group.rows).map('vehicle_id').filter(Boolean).union().value();
						var tasksIds = CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()
							? CollapsedLinkedTasksService.extractTasksFromCollapsedTasks(group.rows).map(
									task => task.id
							  )
							: _.map(group.rows, 'id');

						const selectAllCheckbox = getSelectAllCheckbox(
							[{ fieldName: 'run_id', value: run && run.id }],
							group,
							data.selectedRows,
							extraGroupFieldName
						);

						if (run) {
							var driverId = run.user_id;
							driver = driverId
								? Employees.getEmployeeIfCached(driverId) || _.find(data.employees, { id: driverId })
								: null;
							value = run.route_name;

							if (!value) {
								value =
									TranslationService.instant('DISPATCH_LIST.ROUTE') +
									'-' +
									(run.external_id || run.id);
							}

							const teamId = run.team_id;
							const plannedRoutesByTeamId = data.plannedRoutes.filter(
								plannedRoute => plannedRoute.team_id === teamId
							);
							if (plannedRoutesByTeamId.length > 0 && params.allowModifyPlannedRouteIdentifier) {
								const isRunOverlapping = data.overlappingRunIds.has(run.id);
								const routeLabelClassName =
									!run.planned_route_id || isRunOverlapping
										? 'planner-route-identifier-invalid'
										: 'planner-route-identifier-valid';
								const tooltip = TranslationService.instant('PLANNING.MODIFY_PLANNED_ROUTE_IDENTIFIER');
								const onClickHtml =
									' onclick="$(window).trigger(\'' +
									"handleEditRun'," +
									run.id +
									');' +
									'return false;"';

								value =
									'<a ' +
									'class="' +
									routeLabelClassName +
									'" ' +
									'data-test-id=plannedRouteName_' +
									run.id +
									' ' +
									'title="' +
									tooltip +
									'"' +
									onClickHtml +
									'>' +
									value +
									'</a>';
							}
						}

						var driverName = driver ? driver.name : TranslationService.instant('BIG_MAP.UNASSIGNED');

						var result = selectAllCheckbox + ' <div class="group-title"';
						if (isInner) {
							result += 'style="margin-left: 10px;"';
						}
						result += '>';
						result += `<div class='group-title-name ${isInner ? 'inner-title' : ''}'>` + value + '</div>';

						result += getOrdersHtml(group.rows.length, 0, false);

						if (run && run.started_at && !run.ended_at) {
							let key;
							let eta;

							if (params.realtimeEtaEnabled && driver && driver.eta_to_home) {
								key = 'PLANNING.ETA_TO_BASE';
								eta = driver.eta_to_home;
							} else {
								key = 'PLANNING.SCHEDULED_END_TIME';
								eta = run.scheduled_end_time;
							}

							if (eta) {
								result += getTimeHtml(key, eta);
							}
						} else if (run) {
							result += hasToLeave(group.rows);
						}

						if (run) {
							let trailer;
							if (trailersIds.length) {
								trailer =
									data.vehiclesById[
										trailersIds.find(trailerId => data.vehiclesById[trailerId]?.is_trailer)
									];
							}

							var driverData = {
								runId: run.id,
								itemId: run.user_id,
								teamIds: teamIds
							};

							result += getDriverHTML(driverData, run, driverName, driver, group.rows);

							if (canEditVehicle()) {
								var vehicleData = {
									runId: run.id,
									vehicleId: run.vehicle_id,
									trailerId: trailer?.id,
									teamIds: teamIds,
									userId: run.user_id,
									tasksIds: tasksIds,
									weight: LoadConstraintsService.getWeightAndTotalWeight(
										data,
										group.rows,
										params,
										run,
										driver
									)
								};

								result += getVehicleHTML(run, vehicleData, data.vehiclesById);
								if (Teams.hasTrailer(_.first(teamIds))) {
									result += getTrailerHTML(run, trailer);
								}
							}

							result += LoadConstraintsService.getHandlingUnitsHtml(
								data,
								group.rows,
								params,
								run,
								driver
							);
						}

						if (group.value) {
							result += extraDisplayDataFunc ? extraDisplayDataFunc(group) : '';
						}

						result += '</div></div>';
						return result;
					},
					filterPredefinedValues: function (group) {
						return filterEmptyGroups(false, group);
					},
					comparer: groupByRouteComparer
				};
			}

			function groupByTeam(data, params, showDriversDetails, extraGroupFieldName, isInner) {
				var idsOfTeamsWithoutTasksButWithOnShiftDrivers = getIdsOfTeamsWithoutTasksButWithOnShiftDrivers(
					data.teams,
					data.tasks
				);
				return {
					getter: 'team_id',
					formatter: function (group) {
						var teamId = group.value;
						var teamName = (Teams.teamsByIdMap[teamId] && Teams.teamsByIdMap[teamId].name) || _noTeamName;
						var countInTeam = group.rows.length;
						var unassignedTasksCount = 0;
						var onShiftDrivers = DispatchListService.getOnShiftDriversByTeam(teamId).size;
						var onlineDriversCount = DispatchListService.getOnlineDriversByTeam(teamId).size;
						var returnToBaseDrivers = DispatchListService.getReturnToBaseDriversByTeam(teamId).size;
						var atBaseDrivers = DispatchListService.getAtBaseDriversByTeam(teamId).size;
						var busyDrivers = onShiftDrivers - (returnToBaseDrivers + atBaseDrivers);

						if (group.rows?.length) {
							group.rows.forEach(group => {
								if (group.user_id) return;
								unassignedTasksCount += 1;
							});
						}

						const innerStyle = isInner ? 'style="margin-left: 10px;"' : '';

						var result =
							getSelectAllCheckbox(
								[{ fieldName: 'team_id', value: teamId }],
								group,
								data.selectedRows,
								extraGroupFieldName
							) +
							'<div class="group-title" ' +
							innerStyle +
							`> <div class="group-title-name ${innerStyle ? 'inner-title' : ''}">` +
							teamName +
							'</div>';

						result += getOrdersHtml(unassignedTasksCount, countInTeam, true);

						result += getIconWithDetailsHtml({
							icon: 'online-icon',
							className: 'online',
							count: onlineDriversCount,
							totalCount: onShiftDrivers,
							showTotal: true
						});

						result += getIconWithDetailsHtml({
							icon: 'busy-drivers-icon',
							className: 'busy',
							count: busyDrivers,
							totalCount: onShiftDrivers,
							showTotal: true
						});

						if (params.origin === 'dispatch') {
							result += getIconWithDetailsHtml({
								icon: 'drivers-base-icon',
								className: 'base-drivers',
								count: atBaseDrivers,
								totalCount: onShiftDrivers,
								showTotal: true
							});

							result += getIconWithDetailsHtml({
								icon: 'return-to-base-icon',
								className: 'return-to-base',
								count: returnToBaseDrivers,
								totalCount: onShiftDrivers,
								showTotal: true
							});
						}

						result += '<div class="window-progress-ingrid-container">';

						return result + '</div></div>';
					},
					filterPredefinedValues: function (group) {
						// If user selected specific Teams in filter - other empty teams outside filter must be hidden
						const selectedTeams = data.filterObject?.selectedTeams ?? [];
						const teamsFilterIsEmpty = selectedTeams.length === 0;
						const teamPresentInFilter = selectedTeams.includes(group.value);

						return filterEmptyGroups(
							params.showEmptyTeams && (teamsFilterIsEmpty || teamPresentInFilter),
							group
						);
					},
					predefinedValues: params.showEmptyTeams ? idsOfTeamsWithoutTasksButWithOnShiftDrivers : undefined,
					comparer: groupByTeamsComparer
				};
			}

			function filterEmptyGroups(showEmpty, group) {
				return showEmpty || group.count > 0;
			}

			function filterDriverGroups(showEmptyDrivers, employeeGroup, teamGroup) {
				var employee = Employees.employeeByIdMap[employeeGroup.value];
				return (
					employee &&
					filterEmptyGroups(showEmptyDrivers, employeeGroup) &&
					!_.isNull(employee.active_shift_id) &&
					filterDriverByTeam(employee, teamGroup)
				);
			}

			function filterDriverByTeam(employee, teamGroup) {
				let isValid = false;
				if (teamGroup) {
					isValid = employee.team_ids.includes(teamGroup.value);
					if (employee.active_team_id) {
						isValid = teamGroup.value === employee.active_team_id;
					}
				} else {
					isValid = true;
				}
				return isValid;
			}

			function groupByTeamsComparer(a, b) {
				var teamA = Teams.teamsByIdMap[a.value];
				var teamB = Teams.teamsByIdMap[b.value];
				// handle No team case
				var teamAName = (teamA && teamA.name) || _noTeamName;
				var teamBName = (teamB && teamB.name) || _noTeamName;
				return teamAName.localeCompare(teamBName);
			}

			function groupByUsersComparer(a, b) {
				var userA = Employees.employeeByIdMap[a.value];
				var userB = Employees.employeeByIdMap[b.value];
				var userAName = (userA && userA.name) || _unassigned;
				var userBName = (userB && userB.name) || _unassigned;
				return userAName.localeCompare(userBName);
			}

			function groupByRouteComparer(groupA, groupB) {
				var firstTaskA = groupA.rows[0];
				var firstTaskB = groupB.rows[0];

				var compareByScheduledStartTimeResult = compareRunScheduledStartTime(
					RunsService.getRunFromTask(firstTaskA),
					RunsService.getRunFromTask(firstTaskB)
				);

				if (compareByScheduledStartTimeResult !== 0) {
					return compareByScheduledStartTimeResult;
				}

				var routeA = groupA.value || _noRouteName;
				var routeB = groupB.value || _noRouteName;

				return routeA.toString().localeCompare(routeB.toString());
			}

			function groupByFleetsComparer(groupA, groupB) {
				const fleetAId = groupA.value;
				const fleetBId = groupB.value;

				if (!fleetBId) {
					return -1;
				}
				if (!FleetChecker.isExternalFleet(fleetBId) || !fleetAId) {
					return 1;
				}
				if (!FleetChecker.isExternalFleet(fleetAId)) {
					return -1;
				}

				const fleetAName = fleetAId ? Fleets.getName(fleetAId) : _noFleetName;
				const fleetBName = fleetBId ? Fleets.getName(fleetBId) : _noFleetName;

				return fleetAName.localeCompare(fleetBName);
			}

			/**
			 * return list of team ids that has no tasks
			 */
			function getIdsOfTeamsWithoutTasksButWithOnShiftDrivers(teams, tasks) {
				var teamsWithTasks = {};

				(tasks || []).forEach(function (task) {
					teamsWithTasks[task.team_id] = true;
				});

				// get all team without tasks
				return (
					_.chain(teams)
						.filter(function (team) {
							// need to rethink about the default team issue - identify it by string ?
							return team.name != 'Default Team' && !teamsWithTasks[team.id];
						})
						// team has no tasks, lets see if has any user on shift
						.filter(function (team) {
							return !!DispatchListService.getOnShiftDriversByTeam(team.id).size;
						})
						.map(function (team) {
							return team.id;
						})
						.valueOf()
				);
			}

			function getSelectAllCheckbox(groupValues, group, selectedItems, extraGroupFieldName) {
				if (extraGroupFieldName) {
					groupValues.push({
						fieldName: extraGroupFieldName,
						value: group.rows[0] && group.rows[0][extraGroupFieldName]
					});
				}

				var selectedItemsOfGroup =
					selectedItems &&
					selectedItems.filter(function (task) {
						return groupValues.every(function (groupValue) {
							return task[groupValue.fieldName] == groupValue.value;
						});
					});

				let selectedClass = '';

				if (group.count > 0 && selectedItemsOfGroup) {
					if (selectedItemsOfGroup.length === group.count) {
						selectedClass = ' selected';
					} else if (selectedItemsOfGroup.length > 0) {
						selectedClass = ' part-selected';
					}
				}

				return '<div class="group-select-all ' + selectedClass + '" ></div>';
			}

			function hasToLeave(tasks) {
				var tasksWithRunScheduledStartTime = tasks
					.filter(function (task) {
						var run = RunsService.getRunFromTask(task);
						return run && run.scheduled_start_time;
					})
					.sort(compareRunScheduledStartTimeByTask);

				var needsToLeaveTime;

				if (tasksWithRunScheduledStartTime.length > 0) {
					var taskWithEarliestStartTime = tasksWithRunScheduledStartTime.sort(
						compareRunScheduledStartTimeByTask
					)[0];
					needsToLeaveTime = TimezoneService.getDateByTask(
						taskWithEarliestStartTime,
						RunsService.getRunFromTask(taskWithEarliestStartTime).scheduled_start_time
					);
				} else {
					var needsToLeaveTask = _.chain(tasks)
						.filter(function (task) {
							return task.priority > 0;
						})
						.filter('first_way_point_has_to_leave_by')
						.sortBy('priority')
						.first()
						.value();

					if (!needsToLeaveTask) {
						return ' ';
					}

					needsToLeaveTime = TimezoneService.getDateByTask(
						needsToLeaveTask,
						needsToLeaveTask.first_way_point_has_to_leave_by
					);
				}

				return getTimeHtml('PLANNING.HAS_TO_LEAVE_TITLE', needsToLeaveTime);
			}

			function canEditVehicle() {
				return (
					MerchantConfigurations.enable_vehicles &&
					Authentication.currentUser().has_access(PRIVILEGES_TYPES.ALLOW_ASSIGN_VEHICLE_TO_RUN)
				);
			}

			function compareRunScheduledStartTimeByTask(taskA, taskB) {
				return compareRunScheduledStartTime(
					RunsService.getRunFromTask(taskA),
					RunsService.getRunFromTask(taskB)
				);
			}

			function compareRunScheduledStartTime(runA, runB) {
				var scheduledStartTimeA = _.get(runA, 'scheduled_start_time');
				var scheduledStartTimeB = _.get(runB, 'scheduled_start_time');

				if (!scheduledStartTimeA && scheduledStartTimeB) {
					return 1;
				}

				if (!scheduledStartTimeB && scheduledStartTimeA) {
					return -1;
				}

				if (scheduledStartTimeA < scheduledStartTimeB) {
					return -1;
				}

				if (scheduledStartTimeA > scheduledStartTimeB) {
					return 1;
				}

				return 0;
			}

			function getDriverHTML(driverData, run, driverName, driver, groupedTasks) {
				var isAllowedAnswer = AssignDriverValidations.isAllowedToAssignSelectedTasks(groupedTasks);
				var result = '';

				result += '<div class="icon-container"><span class="grouping-icon user-icon"> </span></div>';

				const disabled =
					isAllowedAnswer.canAssign && isAllowedAnswer.assignableTasks.length > 0 ? '' : ' disabled ';
				const data = JSON.stringify(driverData).replace(/"/g, '&quot;');
				result += `<a run-to-remove='${run.id}'
								team-ids='${driverData.teamIds}'
				 			  	${disabled}
				 			  	class='assign-run-to-driver assign-run-to-driver-link'
				 			  	onclick="$(window).trigger('handleAssignRunToDriver', ${data});return false;">`;

				result += driverName + '<span class="caret"></span>';

				if (driver && driver.id) {
					result += '<img src="/images/check_assigned.png" title="Run Assigned">';
				}

				result += '</a>';

				if (driver && !_.isEmpty(driver.skills)) {
					result += getSkillsString(driver.skills);
				}
				return result;
			}

			function getVehicleHTML(run, vehicleData, vehiclesById) {
				var result = '';
				var vehicle;

				result += '<div class="icon-container"><span class="grouping-icon car-icon"> </span></div>';

				if (run.vehicle_id) {
					vehicle = vehiclesById[run.vehicle_id];
				}

				const data = JSON.stringify(vehicleData).replace(/"/g, '&quot;');
				result += `<a run-to-remove='${run.id}'
 			  				class='assign-run-to-vehicle assign-run-to-vehicle-link'
 			  				onclick="$(window).trigger('handleAssignRunToVehicle', ${data});return false;">`;

				if (vehicle) {
					result +=
						'<span id="vehicle-name-' +
						run.id +
						'" class="">' +
						vehicle.name +
						'</span>' +
						'<span class="caret"></span></a> <span id="vehicle-skills-' +
						run.id +
						'" class="">' +
						getSkillsString(vehicle.skills) +
						'</span>';
				} else {
					result += TranslationService.instant('SELECT_VEHICLES.CHOOSE_VEHICLE') + '</a>';
				}
				return result;
			}

			function getTrailerHTML(run, trailer) {
				var result = '';

				result += '<div class="icon-container"><span class="grouping-icon trailer-icon"> </span></div>';

				result +=
					'<span run-to-remove="' + run.id + '" class="assign-run-to-trailer assign-run-to-trailer-link">';

				if (trailer) {
					result +=
						'<span id="vehicle-name-' +
						run.id +
						'" class="">' +
						trailer.name +
						'</span>' +
						'</span> <span id="vehicle-skills-' +
						run.id +
						'" class="">' +
						getSkillsString(trailer.skills) +
						'</span>';
				} else {
					result += TranslationService.instant('SELECT_TRAILERS.CHOOSE_TRAILER') + '</span>';
				}
				return result;
			}

			function getTimeHtml(key, value) {
				return (
					'<span class="driver-time-text">' +
					TranslationService.instant(key) +
					' ' +
					TimeManagerService.format(value, 'LT') +
					'</span>'
				);
			}

			function getOrdersHtml(count, countInTeam, withTotal) {
				var html =
					'<span class="orders-text">' +
					TranslationService.instant('DISPATCH_LIST.GROUP_DETAILS.ORDERS') +
					':' +
					'</span>';
				html += getGenericCountHTML(count, countInTeam, withTotal);
				return tableDetailsWrapperHTML(html, 'orders');
			}

			function getIconWithDetailsHtml({ icon, className, count, totalCount, showTotal }) {
				var html = getIcon(icon, className);
				html += getGenericCountHTML(count, totalCount, showTotal);
				return tableDetailsWrapperHTML(html);
			}

			//generic HTML functions
			function getIcon(iconName, className) {
				return `<div class='icon-container ${className}'><span class='table-icon ${iconName}'> </span></div>`;
			}

			function getGenericCountHTML(count, totalCount, withTotal) {
				var html = `<span class='count'>` + count + '</span>';

				if (withTotal) {
					html += borderHtml;
					html += '<span class="total-count">' + totalCount + '</span>';
				}

				return html;
			}

			function tableDetailsWrapperHTML(content, extraClass) {
				return `<div class='details-container details-text ${extraClass || ''}'>` + content + '</div>';
			}
		}
	);
