'use strict';

angular.module('bringgApp').factory('authorization', function ($q, IdentityProvider, Merchant) {
	var authorization = {};

	authorization.AuthorizationError = function AuthorizationError(description) {
		this.authenticated = description != null;
		this.description = description || 'User authentication required.';
	};

	authorization.isMerchantCreatedBefore = (merchantCreatedAt, dateCreatedBefore) =>
		moment(merchantCreatedAt).isBefore(moment(dateCreatedBefore));

	authorization.AuthorizationError.prototype = Object.create(Error.prototype);
	authorization.AuthorizationError.prototype.constructor = authorization.AuthorizationError;

	function evaluateAccessByFeatureFlag(featureFlag, user) {
		return (
			!featureFlag ||
			(typeof featureFlag === 'string' && user?.feature_flags?.[featureFlag]) ||
			(typeof featureFlag === 'object' &&
				(featureFlag.not ? !user?.feature_flags?.[featureFlag.name] : user?.feature_flags?.[featureFlag.name]))
		);
	}

	function evaluateAccessByAuthorizationFlags(authorizationFlag, user) {
		if (!authorizationFlag) {
			return true;
		}

		if (typeof authorizationFlag === 'string') {
			return user.has_access(authorizationFlag);
		}

		if (Array.isArray(authorizationFlag)) {
			const [authorizationFlag, authorizationFlagSubField] = authorizationFlag;
			return user.has_access(authorizationFlag, authorizationFlagSubField);
		}

		return authorizationFlag?.or?.some(flag => {
			if (typeof flag === 'string') {
				return user.has_access(flag);
			}

			if (Array.isArray(flag)) {
				const [authorizationFlag, authorizationFlagSubField] = flag;
				return user.has_access(authorizationFlag, authorizationFlagSubField);
			}

			return false;
		});
	}

	function validateByAccessRules(accessRights, user) {
		function extractAuthorizationFlagName(authorizationFlag) {
			if (Array.isArray(authorizationFlag)) {
				return authorizationFlag.join(': ');
			}

			return String(authorizationFlag);
		}

		function generateErrorMessage(accessRight) {
			let message = '';
			if (accessRight?.featureFlag) {
				message = `Feature flag: ${accessRight?.featureFlag}`;
			}

			if (accessRight?.authorizationFlag) {
				message += ' and Privileges: ';
			}

			if (accessRight?.authorizationFlag?.or) {
				message += accessRight?.authorizationFlag?.or
					.map(right => {
						return extractAuthorizationFlagName(right);
					})
					.join(' or ');
			} else {
				message += extractAuthorizationFlagName(accessRight?.authorizationFlag);
			}

			return message + ' required';
		}

		const success = accessRights.or?.some(item => {
			const { featureFlag, authorizationFlag } = item;

			if (!featureFlag && !authorizationFlag) {
				return false;
			}
			return (
				evaluateAccessByFeatureFlag(featureFlag, user) &&
				evaluateAccessByAuthorizationFlags(authorizationFlag, user)
			);
		});

		return {
			success,
			...(success ? {} : { errorMessage: generateErrorMessage(_.first(accessRights.or)) })
		};
	}

	authorization.authorize = function (accessRights) {
		accessRights = accessRights || {};

		return IdentityProvider.byToken().then(function (user) {
			if (user == null) {
				return $q.reject(new authorization.AuthorizationError());
			}
			if (accessRights.adminRequired && !user.admin) {
				return $q.reject(new authorization.AuthorizationError('Admin required'));
			}
			if (accessRights.adminOrDispatcherRequired && !(user.admin || user.dispatcher)) {
				return $q.reject(new authorization.AuthorizationError('Admin of Dispatcher required'));
			}
			if (accessRights.or) {
				const result = validateByAccessRules(accessRights, user);

				if (result?.success) {
					return user;
				} else {
					return $q.reject(new authorization.AuthorizationError(result?.errorMessage));
				}
			}
			if (accessRights.authorizationFlag && !user.has_access(accessRights.authorizationFlag)) {
				return $q.reject(new authorization.AuthorizationError(accessRights.authorizationFlag + ' required'));
			}
			if (accessRights.featureFlag && !user.feature_flags[accessRights.featureFlag]) {
				return $q.reject(new authorization.AuthorizationError(accessRights.featureFlag + ' required'));
			}
			if (accessRights.merchantCreatedBefore) {
				return Merchant.get().then(merchant => {
					if (
						!authorization.isMerchantCreatedBefore(merchant.created_at, accessRights.merchantCreatedBefore)
					) {
						return $q.reject(
							new authorization.AuthorizationError(
								`Merchant created after ${accessRights.merchantCreatedBefore}`
							)
						);
					}

					return user;
				});
			}

			return user;
		});
	};

	return authorization;
});
