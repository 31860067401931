import { action, computed, makeObservable, observable } from 'mobx';
import { PrivilegeTypes } from '@bringg/types';

// Some fields are missing in Bringg.User type but present in object structure
interface AdditionalUserFields {
	original_user_id: number;
	original_merchant_id: number;
}

class UsersStore {
	currentUser: Bringg.User & AdditionalUserFields;

	currentUserLanguage: string = null;

	constructor() {
		makeObservable(this, {
			currentUser: observable,
			currentUserLanguage: observable,
			setCurrentUser: action,
			isImpersonatedMode: computed,
			isAdmin: computed,
			isDispatcher: computed,
			setCurrentUserLanguage: action,
			canAccessCompanies: computed
		});
	}

	setCurrentUserLanguage = (currentUserLanguage: string) => {
		this.currentUserLanguage = currentUserLanguage;
	};

	setCurrentUser = currentUser => {
		this.currentUser = currentUser;
	};

	hasAccess = (fieldName: PrivilegeTypes): boolean => {
		return this.isAdmin || !!this.currentUser.authorization_flags[fieldName]?.value;
	};

	get isImpersonatedMode() {
		return Boolean(this.currentUser && this.currentUser.original_user_id);
	}

	get isAdmin(): boolean {
		return this.currentUser.admin;
	}

	get isDispatcher(): boolean {
		return this.currentUser.dispatcher;
	}

	get canAccessCompanies(): boolean {
		return (
			this.currentUser.admin ||
			(this.currentUser.dispatcher && !this.currentUser.company_id) ||
			this.hasAccess(PrivilegeTypes.ASSIGN_USER_TO_COMPANY)
		);
	}
}

export default UsersStore;
