import React from 'react';

import { useTranslation } from 'react-i18next';
import { ConfirmModal, Button } from '@bringg/react-components';
import { Task as TaskType } from '@bringg/types';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';

import './fleet-assigner-modal-footer.scss';
import './fleet-assigner-task-table.scss';

interface Props {
	assign: () => unknown;
	onClose: () => void;
	isAssigning: boolean;
	isAssignButtonDisabled: boolean;
	error?: string;
	gridSelectedTasks?: TaskType[];
	selectedFleetName: () => string | null;
}

const FleetAssignerModalFooter = ({
	assign,
	isAssignButtonDisabled,
	isAssigning,
	error,
	gridSelectedTasks,
	selectedFleetName,
	onClose
}: Props) => {
	const { t } = useTranslation();

	const assignFleet = () => {
		if (gridSelectedTasks?.length) {
			ConfirmModal({
				title: t('ASSIGN_FLEET.CONFIRMATION_MODAL_TITLE'),
				content: t('ASSIGN_FLEET.CONFIRMATION_MODAL_CONTENT', {
					amount: gridSelectedTasks.length,
					fleet: selectedFleetName()
				}),
				icon: false,
				className: 'confirm-assign-modal',
				okText: t('ASSIGN_FLEET.CONFIRM_ASSIGN'),
				okButtonProps: { className: 'confirm-assign-btn' },
				cancelText: t('ASSIGN_FLEET.CANCEL_ASSIGN'),
				cancelButtonProps: { className: 'cancel-assign-btn' },
				onOk() {
					assign();
				}
			});
			return;
		}

		assign();
	};

	return (
		<div className="fleet-assigner-modal-footer-buttons">
			{error && (
				<div className="error-content" data-test-id="fleet-assigner-error">
					<BringgIcon iconName={BringgFontIcons.Warning} className="error-icon" />
					<span>{error}</span>
				</div>
			)}

			<div className="right-buttons">
				<Button type="link" onClick={onClose}>
					{t('GLOBAL.CANCEL')}
				</Button>
				<Button
					type="primary"
					data-test-id="assign-fleet-btn"
					disabled={isAssignButtonDisabled}
					loading={isAssigning}
					onClick={() => assignFleet()}
				>
					{t('ASSIGN_FLEET.ASSIGN_FLEET')}
				</Button>
			</div>
		</div>
	);
};

export default FleetAssignerModalFooter;
