/* eslint-disable angular/di-unused */
'use strict';
/*global merge_objects:false */

angular
	.module('bringgApp')
	.factory(
		'Employee',
		function ($resource, BringgSDK, WEB_API_URL, Authentication, MerchantConfigurations, bringg_utils) {
			var Employee = $resource(
				WEB_API_URL + '/users/:id',
				{ id: '@id' },
				{
					closer: {
						method: 'GET',
						params: { id: '@id' },
						url: WEB_API_URL + '/api/tasks/:id/closest_users',
						isArray: true
					},
					recommended: {
						method: 'GET',
						params: { id: '@id' },
						url: WEB_API_URL + '/api/tasks/:id/recommended_users',
						isArray: true
					},
					save_with_merchant: { method: 'POST', url: WEB_API_URL + '/api/merchant/register_merchant' },
					drivers: { method: 'GET', isArray: true, url: WEB_API_URL + '/users/drivers/' },
					makeAdmin: { method: 'POST', url: WEB_API_URL + '/users/:id/admin' },
					regeneratePassword: { method: 'POST', url: WEB_API_URL + '/users/:id/regenerate_password' },
					ask_for_log: { method: 'POST', url: WEB_API_URL + '/users/:id/ask_for_log' },
					password_reminder: { method: 'POST', url: WEB_API_URL + '/users/remind' },
					logs: { method: 'GET', url: WEB_API_URL + '/users/:id/logs', isArray: true },
					startShift: { method: 'POST', url: WEB_API_URL + '/users/:id/shift' },
					endShift: { method: 'DELETE', url: WEB_API_URL + '/users/:id/shift' },
					endActiveOrders: { method: 'POST', url: WEB_API_URL + '/users/:id/tasks/end_active_tasks' },
					sendOrderList: { method: 'POST', url: WEB_API_URL + '/users/:id/tasks/email' },
					confirm_email: { method: 'POST', url: WEB_API_URL + '/users/confirm' },
					resend_confirmation_instructions: {
						method: 'POST',
						url: WEB_API_URL + '/users/resend_confirmation_instructions'
					},
					updateTeams: { method: 'POST', url: WEB_API_URL + '/users/:user_id/teams/' },
					sendResetPasswordInstructions: {
						method: 'POST',
						url: WEB_API_URL + '/users/send_reset_password_instructions/'
					},
					leftHome: { method: 'POST', params: { id: '@id' }, url: WEB_API_URL + '/users/:id/left_home' },
					gotHome: { method: 'POST', params: { id: '@id' }, url: WEB_API_URL + '/users/:id/got_home' },
					doneNotCashedOut: { method: 'GET', url: WEB_API_URL + '/users/:id/tasks/done_not_cashed_out' },
					cashOut: { method: 'POST', url: WEB_API_URL + '/users/:id/tasks/cash_out' }
				}
			);

			var _sdk;

			function employeeService(method) {
				return function () {
					var args = arguments;
					return BringgSDK.getInstancePromise().then(function (bringg) {
						_sdk = bringg.users;
						return bringg.users[method].apply(bringg.users, args);
					});
				}.bind(this);
			}

			Employee.allKeysetPagination = employeeService('getAllKeysetPagination');
			Employee.allDrivers = employeeService('getAllDrivers');
			Employee.allAdminsAndDispatchers = employeeService('getAllAdminsAndDispatchers');
			Employee._get = employeeService('get');
			Employee.save = employeeService('create');
			Employee.invite = employeeService('invite');
			Employee.unlock = employeeService('unlock');
			Employee.validatePhone = employeeService('validatePhone');
			Employee.inviteByEmail = employeeService('inviteByEmail');
			Employee.closedTasks = employeeService('closedTasks');
			Employee.assignedTasksCount = employeeService('assignedTasksCount');
			Employee.update = employeeService('update');
			Employee.delete = employeeService('delete');
			Employee.byUserType = employeeService('byUserType');
			Employee.isDriverOnly = function (employee) {
				return employee.driver && !employee.admin && !employee.dispatcher;
			};
			Employee.getDriverCost = employeeService('getDriverCost');

			Employee.getSdk = () => _sdk;

			Employee.getByIdFromStoredItems = function (driverId) {
				const sdk = Employee.getSdk();

				if (sdk) {
					return sdk.usersStore.getItem(driverId);
				}

				return undefined;
			};

			Employee.enabledAllowUsernamePasswordLogin = Authentication.currentUser()
				? Authentication.currentUser()?.feature_flags?.enable_allow_username_password_login || false
				: false;
			Employee.showSSOLoginInfo = false;

			if (Employee.enabledAllowUsernamePasswordLogin) {
				const isSSOConfigured = bringg_utils.checkIfSSOConfigured(MerchantConfigurations) || false;
				const currentUser = Authentication.currentUser();
				Employee.showSSOLoginInfo = currentUser
					? currentUser.admin && !currentUser.driver && isSSOConfigured
					: false;
			}

			return Employee;
		}
	)
	.factory(
		'Employees',
		function (
			$rootScope,
			SocketPubSub,
			Employee,
			MerchantConfigurations,
			Teams,
			UserTypes,
			$log,
			Authentication,
			DEFAULT_RT_THROTTLE,
			$q,
			toastr,
			$translate,
			$location,
			AirbrakeService,
			VehiclesService,
			bringg_utils
		) {
			var Employees = {};

			var employeesDefer = $q.defer();
			var fetchedEmployees = [];
			var employeesPromise = employeesDefer.promise;
			var EMPLOYEE_AMOUNT_TO_FETCH = 2500;
			const CLICK_AND_COLLECT_CUSTOMER_DUMMY_NAME = $translate.instant(
				'ORDER.CLICK_AND_COLLECT_CUSTOMER_DUMMY_NAME'
			);

			Employees._initCache = function () {
				Employees.allEmployees = employeesPromise;
				Employees.employeeByIdMap = {};
				Employees.initial_fetch_drivers_only = false;
				Employees.show_vehicle_name_in_employee_list = false;
				Employees.customerUsersArray = [];
				Employees.onShiftDriversByTeam = new Map();
				Employees.atBaseDriversByTeam = new Map();
				Employees.returnToBaseDriversByTeam = new Map();
				Employees.onlineDriversByTeam = new Map();
				Employees.finishedInitialEmployeeFetch = false;
				Employees.vehicles = new Map();
			};

			Employees._initCache();

			Employees._isDriver = function (employee) {
				return !!(employee && employee.driver);
			};

			Employees._addEmployeeToCustomerCache = function (id) {
				// object in array has to be the pointer to employee object in employeeByIdMap to ensure updates by ref
				var customerUser = Employees.getEmployeeIfCached(id);
				Employees.customerUsersArray.push(customerUser);
			};

			Employees._updateEmployeesCacheObject = function (updatedEmployee) {
				var cachedEmployee = Employees.getEmployeeIfCached(updatedEmployee.id);
				Employees.employeeByIdMap[parseInt(updatedEmployee.id, 10)] = cachedEmployee
					? Object.assign(cachedEmployee, updatedEmployee)
					: updatedEmployee;
			};

			Employees._updateEmployeesCache = function (updatedEmployee) {
				var isCustomerUser = updatedEmployee.customer === true;

				Employees._updateEmployeesCacheObject(updatedEmployee);

				if (isCustomerUser) {
					Employees._addEmployeeToCustomerCache(updatedEmployee.id);
				}
			};

			Employees.preProcessEmployee = function (employee) {
				// convert to number
				if (_.isString(employee.user_type_id)) {
					employee.user_type_id = Number(employee.user_type_id);
				}

				if (_.isString(employee.customer)) {
					employee.customer = employee.customer === 'true';
				}

				var fetchUserTypesPromise = $q.resolve();

				if (MerchantConfigurations.enable_user_types && employee.user_type_id) {
					fetchUserTypesPromise = UserTypes.byUser(employee).then(function (userType) {
						employee.userType = userType;
					});
				}

				if (employee.vehicle_id && Employees.show_vehicle_name_in_employee_list) {
					employee.vehicle = Employees.vehicles.get(employee.vehicle_id);
				}

				return $q.all([fetchUserTypesPromise]).then(function () {
					var preProcessEmployee = bringg_utils.getValueFromApplications('pre_process_employee');

					if (!_.isUndefined(preProcessEmployee) && !_.isUndefined(employee.user_type_id)) {
						var userTypeIds = bringg_utils.getValuesFromApplicationMerchantConfiguration([
							'driving_user_type',
							'on_site_user_type'
						]);
						var drivingUserTypeId = userTypeIds['driving_user_type'];
						var onSiteUserTypeId = userTypeIds['on_site_user_type'];

						var onShiftUserTypeIds = [drivingUserTypeId, onSiteUserTypeId];

						// keep it for state mode of user, to know last 'pre processed value'
						employee.real_active_shift_id = _.isBoolean(employee.active_shift_id)
							? employee.real_active_shift_id
							: employee.active_shift_id;
						employee.active_shift_id =
							employee.real_active_shift_id && _.includes(onShiftUserTypeIds, employee.user_type_id);
						employee.at_home = employee.user_type_id && employee.user_type_id !== drivingUserTypeId;
					}

					Employees._updateEmployeesCache(employee);
				});
			};

			Employees.getEmployeeRelationships = function (employee) {
				var fetchingEmployeeRelationshipsPromises = [];

				if (employee.team_ids) {
					fetchingEmployeeRelationshipsPromises.push(
						Teams.byUser(employee).then(function (teams) {
							employee.teams = teams;
						})
					);
				}

				if (employee.allowed_user_type_ids && employee.allowed_user_type_ids.length > 0) {
					fetchingEmployeeRelationshipsPromises.push(
						UserTypes.byIds(employee.allowed_user_type_ids).then(function (userTypes) {
							employee.allowed_user_types = userTypes.filter(userType => userType);
						})
					);
				}

				return $q.all(fetchingEmployeeRelationshipsPromises).then(function () {
					return employee;
				});
			};

			Employees.getEmployeeCompleteDetails = function (employee) {
				return $q.all([Employees.getEmployeeRelationships(employee), Employees.preProcessEmployee(employee)]);
			};

			Employees.updateFetchedEmployees = function () {
				var allUsers = Object.values(Employees.employeeByIdMap);
				bringg_utils.setArrayItems(fetchedEmployees, allUsers);
				employeesDefer.resolve(fetchedEmployees);
			};

			Employees.fetchAllAdminsAndDispatchers = function () {
				var deffered = $q.defer();
				Employees.loadWithKeysetPagination(Employee.allAdminsAndDispatchers, null, deffered);

				return deffered.promise;
			};

			Employees.getVehicles = function () {
				return VehiclesService.getAll().then(function (vehicles) {
					Employees.vehicles = new Map(vehicles.map(vehicle => [vehicle.id, vehicle]));
				});
			};

			Employees.asyncSearchByName = function (searchString, options) {
				return $q(function (resolve, reject) {
					Employees.allEmployees.then(function (list) {
						var results = [];
						var finished = false;
						var cursor = 0;
						var bunch = 100;
						var limit = (options && options.limit && Number(options.limit)) || 10;
						var skipDrivers = (options && options.skipDrivers && Boolean(options.skipDrivers)) || false;
						var excludeIds =
							options && options.excludeIds && Array.isArray(options.excludeIds)
								? options.excludeIds
								: [];
						var lowerSearchString = (searchString || '').toLowerCase();
						async.until(
							function test() {
								return finished;
							},
							function iter(next) {
								$q(function (resolve) {
									for (var i = cursor; i < Math.min(cursor + bunch, list.length); i++) {
										if (
											!excludeIds.includes(list[i].id) &&
											(!skipDrivers ||
												!(list[i].driver && !list[i].admin && !list[i].dispatcher)) &&
											(!lowerSearchString ||
												(list[i].name || '').toLowerCase().includes(lowerSearchString))
										) {
											results.push(list[i]);
											if (limit && results.length >= limit) {
												finished = true;
												break;
											}
										}
									}
									cursor = i;
									if (cursor >= list.length || (limit && results.length >= limit)) {
										finished = true;
									}
									resolve();
								}).then(next);
							},
							function done(err) {
								if (err) {
									reject(err);
								}
								resolve(results);
							}
						);
					}, reject);
				});
			};

			Employees.initOpenEmployees = function () {
				$translate.onReady(function () {
					Employees.toast = toastr.info(
						$translate.instant('MAIN.USER_LOADING_MESSAGE'),
						'<i style="margin-right: 5px" class="bringg-icon bringg-icon-loader spin"></i>' +
							$translate.instant('MAIN.USER_LOADING_TITLE'),
						{ timeOut: 0, extendedTimeOut: 0, closeButton: true }
					);
				});

				Employees.loadWithKeysetPagination = function (endPointCallback, nextPageCursor, promiseDefer) {
					var isFirstPage = _.isNil(nextPageCursor);

					endPointCallback({ limit: EMPLOYEE_AMOUNT_TO_FETCH, cursor: nextPageCursor }).then(function (
						response
					) {
						var users = response.users;
						nextPageCursor = response.next_page_cursor;

						if (isFirstPage) {
							Employees.afterFirstPageEmployeesLoaded(users);
						} else {
							Employees.afterPageEmployeesLoaded(users);
						}

						if (nextPageCursor) {
							Employees.loadWithKeysetPagination(endPointCallback, nextPageCursor, promiseDefer);
						} else {
							Employees.updateFetchedEmployees();

							if (promiseDefer) {
								promiseDefer.resolve();
							}

							Employees.finishedInitialEmployeeFetch = true;
							toastr.clear(Employees.toast);
						}
					});
				};

				Employees.loadAllEmployees = function () {
					Employees.loadWithKeysetPagination(Employee.allKeysetPagination);
				};

				Employees.loadAllDrivers = function () {
					Employees.loadWithKeysetPagination(Employee.allDrivers);
				};

				Employees.afterFirstPageEmployeesLoaded = function (users) {
					listenToSocketEvents();
					Employees.afterPageEmployeesLoaded(users);
				};

				Employees.afterPageEmployeesLoaded = function (users) {
					return Employees.handleEmployees(users);
				};

				$q.all([Authentication.featureFlags(), Teams.all()]).then(function (results) {
					var featureFlags = results[0];
					Employees.initial_fetch_drivers_only = featureFlags.initial_fetch_drivers_only;
					Employees.show_vehicle_name_in_employee_list = featureFlags.show_vehicle_name_in_employee_list;

					function load() {
						if (Employees.initial_fetch_drivers_only) {
							Employees.loadAllDrivers();
						} else {
							Employees.loadAllEmployees();
						}
					}

					if (Employees.show_vehicle_name_in_employee_list) {
						Employees.getVehicles().finally(function () {
							load();
						});
					} else {
						load();
					}
				});
			};

			Employees.updateDriversCounterByTeam = function (driver) {
				var isDriverOnShift = !!driver.active_shift_id;
				var isDriverFree = !!Employees.isFree(driver);
				var isDriverAtHome = !!driver.at_home;
				var isDriverOnline = !!Employees.filterIsOnline(driver);
				var driverId = driver.id;

				_.each(driver.team_ids, function (teamId) {
					Employees.updateDriversByKeyAndTeam(teamId, 'onShiftDriversByTeam', driverId, isDriverOnShift);
					Employees.updateDriversByKeyAndTeam(
						teamId,
						'atBaseDriversByTeam',
						driverId,
						isDriverOnShift && isDriverFree && isDriverAtHome
					);
					Employees.updateDriversByKeyAndTeam(
						teamId,
						'returnToBaseDriversByTeam',
						driverId,
						isDriverOnShift && isDriverFree && !isDriverAtHome
					);

					Employees.updateDriversByKeyAndTeam(
						teamId,
						'onlineDriversByTeam',
						driverId,
						isDriverOnShift && isDriverOnline
					);
				});
			};

			Employees.handleEmployees = function (employees) {
				var getAllEmployeesCompleteDetails = _.map(employees, function (employee) {
					employee.driver && Employees.updateDriversCounterByTeam(employee);
					Employees._updateEmployeesCache(employee);
					return Employees.getEmployeeCompleteDetails(employee);
				});

				return $q.all(getAllEmployeesCompleteDetails).then(function () {
					// we must emit notify employee changes after we got all employee pre processing done (to reduce UI refresh overload)
					Employees.notifyChanges();

					$rootScope.$broadcast('employees loaded');
					$log.info('Employees.initOpenEmployees - fetched employees', employees.length);
					$rootScope.$broadcast('remove x', { alertStringId: 'loading drivers' });
					return employees;
				});
			};

			if (Authentication.isLoggedIn() && Authentication.currentUser()) {
				Employees.initOpenEmployees();
			}

			Employees.filterIsOnline = function (employee) {
				// employee status is null if they never logged in
				if (employee && employee.status) {
					return employee.status.toLowerCase() === 'online';
				}
			};

			Employees.filterIsOffline = function (employee) {
				// employee status is null if they never logged in
				if (employee && employee.status) {
					return employee.status.toLowerCase() === 'offline';
				}
			};

			var waitForUser = function (userId, successCb) {
				var user = Employees.getEmployeeIfCached(userId);

				if (user) {
					if (successCb) {
						successCb(user);
					}

					return $q.resolve(user);
				} else {
					var deffered = $q.defer();

					setTimeout(function () {
						Employees.allEmployees.then(function () {
							deffered.resolve(waitForUser(userId, successCb));
						});
					}, 500);

					return deffered.promise;
				}
			};

			Employees.updateEmployeeDataInCache = function (employee, successCb) {
				if (successCb) {
					successCb(employee);
				}
				return Employees.updateEmployee(employee).then(function () {
					return Employees.getEmployeeIfCached(employee.id);
				});
			};

			Employees.get = function (options) {
				const userId = parseInt(arguments[0].id, 10);
				const get_arguments = arguments,
					successCb = getSuccessCallback(get_arguments);
				var user = Employees.getEmployeeIfCached(userId);

				if (!user && options.customer) {
					return Employees.updateEmployeeDataInCache(
						{ id: userId, name: CLICK_AND_COLLECT_CUSTOMER_DUMMY_NAME, customer: true },
						successCb
					);
				}

				const force = options.force === true;
				const deffered = $q.defer();

				deffered.resolve(user);

				if (!user && (force || $location.path() === '/kds')) {
					return Employee._get(userId).then(employee =>
						Employees.updateEmployeeDataInCache(employee, successCb)
					);
				}

				if (user) {
					return deffered.promise.then(function (employee) {
						if (successCb) {
							successCb(employee);
						}

						return employee;
					});
				} else {
					return Employees.allEmployees.then(function () {
						user = Employees.getEmployeeIfCached(userId);
						if (Employees.finishedInitialEmployeeFetch && !user) {
							$log.warn(
								'Employee (' + userId + ') not found in cache after initial employee fetch has finished'
							);
							return $q.resolve(null);
						} else {
							return waitForUser(userId, successCb);
						}
					});
				}
			};

			Employees.drivers = function (options) {
				var successCb = getSuccessCallback(arguments);
				return Employees.allEmployees.then(function () {
					var drivers = Employee.getSdk().usersStore.getAllLocalDrivers();
					if (successCb) {
						successCb(drivers);
					}

					return drivers;
				});
			};

			Employees.getOnlineDriversCountByTeam = function (teamId) {
				return Employee.getSdk().usersStore.getOnlineDriversCountByTeam(teamId);
			};

			Employees.driversWithCustomers = function (options) {
				var successCb = getSuccessCallback(arguments);
				return Employees.allEmployees.then(function () {
					var driversWithCustomers = []
						.concat(Employee.getSdk().usersStore.getAllLocalDrivers())
						.concat(Employees.customerUsersArray);
					if (successCb) {
						successCb(driversWithCustomers);
					}
					return driversWithCustomers;
				});
			};

			Employees.isFree = function (employee) {
				return employee.sub && employee.sub.toLowerCase() === 'free';
			};

			Employees.isOnTheWay = function (employee) {
				return employee.sub && _.includes(['started', 'checked-in', 'late'], employee.sub.toLowerCase());
			};

			Employees.isOnShift = function (employee) {
				return (
					employee.active_shift_id > 0 &&
					(!employee.active_team_id ||
						Authentication.currentUser().admin ||
						Authentication.currentUser().team_ids.includes(employee.active_team_id))
				);
			};

			Employees.hasLocation = function (employee) {
				return _.every([employee.lat, employee.lng]);
			};

			UserTypes.onDefaultUserTypeChanged(function (defaultUserType) {
				Employees.allEmployees.then(function (employees) {
					_.each(employees, function (employee) {
						if (_.isNull(employee.user_type_id)) {
							employee.userType = defaultUserType;
						}
					});
				});
			});

			Employees.getEmployeeIfCached = function (id) {
				return Employees.employeeByIdMap[parseInt(id, 10)];
			};

			Employees.updateEmployee = function (updatedEmployee) {
				if (!updatedEmployee) {
					return null;
				}

				var cachedEmployeeObject = Employees.getEmployeeIfCached(updatedEmployee.id);
				var isNewEmployee = !cachedEmployeeObject;

				Employees._updateEmployeesCache(updatedEmployee);

				if (isNewEmployee) {
					// _updateEmployeesCache would create employee object reference, if it didn't exist
					cachedEmployeeObject = Employees.getEmployeeIfCached(updatedEmployee.id);
				}

				return Employees.getEmployeeCompleteDetails(cachedEmployeeObject).then(function () {
					cachedEmployeeObject.driver && Employees.updateDriversCounterByTeam(cachedEmployeeObject);
					if (isNewEmployee) {
						Employees.allEmployees = Employees.allEmployees.then(employees => {
							if (employees) {
								employees.push(cachedEmployeeObject);
							}

							return employees;
						});
						Employees.onNewEmployee(cachedEmployeeObject);
					} else {
						Employees.onUpdatedEmployee(cachedEmployeeObject);
					}
					Employees.notifyChanges();
				});
			};

			Employees.onNewEmployee = function (newEmployee) {
				$rootScope.$broadcast('newEmployeeAdded', newEmployee);
			};

			Employees.onUpdatedEmployee = function (updatedEmployee) {
				$rootScope.$broadcast('employeeUpdated', updatedEmployee);
			};

			Employees.update = function (userId, user) {
				Employee.update(userId, user).then(Employees.onUpdatedEmployee);
			};

			Employees._removeFromArrayByKey = function (array, item, key) {
				var itemIndex = _.findIndex(array, function (arrayItem) {
					return arrayItem[key] === item;
				});
				if (itemIndex !== -1) {
					array.splice(itemIndex, 1);
				}
			};

			Employees.onDeletedEmployee = function (removedEmployee) {
				$log.info('Employee (' + removedEmployee.user_id + ') removed');
				return Employees.allEmployees.then(function (employees) {
					var cachedEmployee = Employees.getEmployeeIfCached(removedEmployee.user_id);
					if (cachedEmployee) {
						Employees._removeFromArrayByKey(employees, removedEmployee.user_id, 'id');

						delete Employees.employeeByIdMap[removedEmployee.user_id];

						_.each(removedEmployee.team_ids, function (teamId) {
							var driverId = removedEmployee.id;
							Employees.updateDriversByKeyAndTeam(teamId, 'onShiftDriversByTeam', driverId, false);
							Employees.updateDriversByKeyAndTeam(teamId, 'atBaseDriversByTeam', driverId, false);
							Employees.updateDriversByKeyAndTeam(teamId, 'returnToBaseDriversByTeam', driverId, false);
							Employees.updateDriversByKeyAndTeam(teamId, 'onlineDriversByTeam', driverId, false);
						});

						$rootScope.$broadcast('employeeDeleted', removedEmployee);
						Employees.notifyChanges();
					}
				});
			};

			Employees.getDriversByKeyAndTeam = function (teamId, key) {
				return Employees[key].get(teamId) || new Set();
			};

			Employees.updateDriversByKeyAndTeam = function (teamId, key, driverId, shouldAdd) {
				var driversSet = Employees[key].get(teamId) || new Set();
				shouldAdd ? driversSet.add(driverId) : driversSet.delete(driverId);
				Employees[key].set(teamId, driversSet);
			};

			//===========================================================
			// handle updates via sockets
			//===========================================================
			function listenToSocketEvents() {
				$log.info('Employees.listenToSocketEvents - listening to socket events');

				SocketPubSub.on('new employee', Employees.updateEmployee);

				SocketPubSub.on('delete employee', Employees.onDeletedEmployee);

				SocketPubSub.on('employee update', Employees.updateEmployee);
			}

			//===========================================================
			// clear data on logout
			//===========================================================
			$rootScope.$on('loggedout', Employees._initCache);

			$rootScope.$on('loggedin', function () {
				Employees.initOpenEmployees();
			});

			/**
			 * send list change notification
			 */
			Employees.notifyChanges = _.throttle(function () {
				$rootScope.$broadcast('employees list update');
			}, DEFAULT_RT_THROTTLE);

			return Employees;
		}
	);
