import React, { useEffect, useMemo, useState } from 'react';

import { Spinner, Tabs } from '@bringg/react-components';
import { useTranslation } from 'react-i18next';
import { useSearchParam } from '@bringg-frontend/hooks';
import { PrivilegeTypes } from '@bringg/types';
import { useHasFeatureFlag } from '@bringg-frontend/hooks';

import PlannedDeliveryWindowsView, {
	NO_TEAM
} from 'bringg-web/features/planned-delivery-windows/planned-delivery-windows-view';
import ExclusionWindowView from 'bringg-web/features/planned-delivery-windows/exclusion-windows/exclusion-windows-view';
import { useStores } from 'bringg-web/recipes';
import { getValidTeam } from 'bringg-web/features/planned-delivery-windows/services/get-valid-team';
import { useHasAccess, isDispatcher } from 'bringg-web/utils/privileges';

import styles from './planned-delivery-windows.module.scss';

enum PlannedDeliveryWindowsTabs {
	CALENDAR = 'CALENDAR',
	BLACKOUTS = 'BLACKOUTS'
}

const PlannedDeliveryWindows = () => {
	const { t } = useTranslation();
	const isAllowedToViewBlackouts = useHasAccess(PrivilegeTypes.VIEW_EXCLUSION_WINDOWS);
	const enableSlotsCompanyPrivilege = useHasFeatureFlag('Enable_slots_company_privilege');
	const [pageLoading, setPageLoading] = useState(true);
	const [activeTab, setActiveTab] = useSearchParam<PlannedDeliveryWindowsTabs>('tab', {
		defaultValue: PlannedDeliveryWindowsTabs.CALENDAR,
		resetOtherParamsOnChange: false,
		defaultOptions: {
			updateSearch: true,
			resetOthersParams: false
		}
	});

	const { teamsStore, merchantStore, serviceArea, servicePlansStore, usersStore } = useStores();
	const defaultTeamValue =
		enableSlotsCompanyPrivilege && isDispatcher(usersStore.currentUser) && teamsStore.allCurrentUserTeams.length > 0
			? teamsStore.allCurrentUserTeams[0].id
			: NO_TEAM;

	const [activeTeam, setTeam] = useSearchParam<number>('team', {
		defaultValue: defaultTeamValue,
		resetOtherParamsOnChange: false,
		defaultOptions: {
			updateSearch: true,
			resetOthersParams: false
		}
	});

	useEffect(() => {
		const fetch = async () => {
			const promises: any[] = [teamsStore.fetchAll(), serviceArea.loadAll(), servicePlansStore.fetchAll()];
			if (!merchantStore.isFetched) {
				promises.unshift(merchantStore.fetch());
			}
			await Promise.all(promises);
			const correctTeam = getValidTeam(activeTeam);
			if (correctTeam !== activeTeam) {
				setTeam(correctTeam);
			}
			setPageLoading(false);
		};
		fetch();
	}, []);

	const setTeamInUrl = (teamId: number) => {
		setTeam(teamId);
	};

	const items = useMemo(() => {
		const labels = [
			{
				label: t(`PLANNED_DELIVERY_WINDOWS_TABS_LABELS.${PlannedDeliveryWindowsTabs.CALENDAR}`),
				key: PlannedDeliveryWindowsTabs.CALENDAR,
				children: <PlannedDeliveryWindowsView setTeamInUrl={setTeamInUrl} selectedTeamId={activeTeam} />
			}
		];
		if (isAllowedToViewBlackouts) {
			labels.push({
				label: t(`PLANNED_DELIVERY_WINDOWS_TABS_LABELS.${PlannedDeliveryWindowsTabs.BLACKOUTS}`),
				key: PlannedDeliveryWindowsTabs.BLACKOUTS,
				children: <ExclusionWindowView setTeamInUrl={setTeamInUrl} selectedTeamId={activeTeam} />
			});
		}

		return labels;
	}, [activeTeam]);

	const getExtraContent = () => {
		return {
			left: (
				<span className={styles.extraContent}>
					{t('PLANNED_DELIVERY_WINDOWS_TABS_LABELS.BEFORE_TABS_TEXT')}
				</span>
			)
		};
	};

	return (
		<div className={styles.plannedDeliveryWindows}>
			{pageLoading && <Spinner />}
			{!pageLoading && (
				<Tabs
					tabBarExtraContent={getExtraContent()}
					destroyInactiveTabPane
					items={items}
					activeKey={activeTab}
					onChange={key => setActiveTab(key as PlannedDeliveryWindowsTabs)}
				/>
			)}
		</div>
	);
};

export default PlannedDeliveryWindows;
