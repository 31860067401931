'use strict';

angular
	.module('bringgApp')
	.service('FixGeocodingDataService', function (SocketPubSub, $rootScope, TasksData, DEFAULT_RT_THROTTLE) {
		var FixGeocodingDataService = {};

		var notGeocodedWayPoints = [];
		var taskTypeToWatch = null;
		var isListeningToChanges = false;

		var disposeLazyTaskListUpdate = _.noop;
		var disposeTaskListUpdate = _.noop;

		FixGeocodingDataService.findNotGeocodedWayPoints = function (task) {
			return _.filter(task.way_points, function (way_point) {
				return !way_point.pending_geocode && (!_.isNumber(way_point.lat) || !_.isNumber(way_point.lng));
			});
		};

		/**
		 * Returns a array of task and way points pairs
		 * where the way points don't have lat & lng (not gecodoed)
		 * @param tasks
		 * @returns {Array} array of { way_point, task } pairs.
		 * @private
		 */
		FixGeocodingDataService.findWayPointsWithoutGeolocation = function (tasks) {
			return _.chain(tasks).map(FixGeocodingDataService.findNotGeocodedWayPoints).flatten().value();
		};

		/**
		 *
		 * @param {String} taskTypeToFetch - if we are on planning, this should be planning, otherwise open.
		 * @returns {Array<WayPoint>} returns
		 */
		FixGeocodingDataService.all = function (taskTypeToFetch) {
			// If we moved from planning to dispatch for example
			if (taskTypeToFetch !== taskTypeToWatch) {
				notGeocodedWayPoints = [];
			}

			// If we already have not geocoded way points list...
			if (!_.isEmpty(notGeocodedWayPoints)) {
				return notGeocodedWayPoints;
			}

			// Mark this task type ("open" / "planning") to listen for `lazy task list update`
			taskTypeToWatch = taskTypeToFetch;
			var tasks = taskTypeToFetch === 'open' ? TasksData.getOpenTasks() : TasksData.getPlanningTasks();

			notGeocodedWayPoints = FixGeocodingDataService.findWayPointsWithoutGeolocation(tasks || []);

			notifyChanges();
			listenToChanges();

			return notGeocodedWayPoints;
		};

		function notifyChanges() {
			$rootScope.$broadcast('not geocoded waypoints update', notGeocodedWayPoints);
		}

		function onWayPointUpdated(updatedWayPoint) {
			var taskId = updatedWayPoint.task_id;

			if (!TasksData.isOpenTask(taskId) && !TasksData.isPlanningTask(taskId)) {
				return;
			}

			// Since this is a partial way point update,
			// we need to fill all of the information
			var task = TasksData.get(taskId);
			var wayPoint = _.find(task.way_points, { id: updatedWayPoint.way_point_id });

			// apply the partial update
			var fullWayPoint = _.extend({}, wayPoint, updatedWayPoint);

			if (_.isNumber(fullWayPoint.lat) && _.isNumber(fullWayPoint.lng)) {
				notGeocodedWayPoints = _.reject(notGeocodedWayPoints, { id: updatedWayPoint.way_point_id });
			} else {
				var wayPointIdx = _.findIndex(notGeocodedWayPoints, { id: updatedWayPoint.way_point_id });
				if (wayPointIdx === -1) {
					notGeocodedWayPoints.push(fullWayPoint);
				}
			}

			notifyChanges();
		}

		function onTaskListUpdate() {
			if (!taskTypeToWatch) return;

			var allTasks = taskTypeToWatch === 'open' ? TasksData.getOpenTasks() : TasksData.getPlanningTasks();
			notGeocodedWayPoints = FixGeocodingDataService.findWayPointsWithoutGeolocation(allTasks);

			notifyChanges();
		}

		var handleLazyTaskListUpdate = _.throttle(onTaskListUpdate, DEFAULT_RT_THROTTLE * 2, { leading: false });

		function listenToChanges() {
			// Listen only one to changes
			if (isListeningToChanges) return;
			isListeningToChanges = true;

			SocketPubSub.on('waypoint updated', onWayPointUpdated);

			disposeLazyTaskListUpdate = $rootScope.$on('lazy task list update', handleLazyTaskListUpdate);
			disposeTaskListUpdate = $rootScope.$on('task list update', handleLazyTaskListUpdate);
		}

		FixGeocodingDataService.dispose = function () {
			notGeocodedWayPoints = [];
			isListeningToChanges = false;

			SocketPubSub.removeListener('waypoint updated', onWayPointUpdated);
			disposeLazyTaskListUpdate();
			disposeTaskListUpdate();
		};

		return FixGeocodingDataService;
	})
	.service('FixGeocodingModalService', function ($uibModal, Task) {
		var MAX_WAYPOINTS_TO_FIX = 50;

		var FixGeocodingModalService = {};

		FixGeocodingModalService.open = function (notGeocedWayPoints) {
			var taskIds = _.take(_.map(notGeocedWayPoints, 'id'), MAX_WAYPOINTS_TO_FIX);

			// Fetch the tasks again, to fill missing data like city, borough (this can be missing in big map, for example)
			return Task.notGeocoded({ way_point_ids: taskIds }).$promise.then(function (response) {
				return $uibModal.open({
					size: 'lg',
					windowClass: 'fix-geocoding-modal-open',
					backdropClass: 'fix-geocoding-backdrop',
					templateUrl: 'scripts/features/geocoding/fix-geocoding.html',
					controller: 'FixGeocodingController',
					resolve: {
						wayPoints: _.constant(response.way_points)
					}
				});
			});
		};

		return FixGeocodingModalService;
	});
