'use strict';

angular
	.module('bringgApp')
	.controller(
		'SlickGridWrapperController',
		function (
			Authentication,
			$scope,
			$element,
			$attrs,
			$window,
			localStorageService,
			$timeout,
			bringg_utils,
			$compile,
			$rootScope,
			$log,
			ScopeApplier,
			CrossApplicationService,
			LockedTaskService,
			SlickGridConfigService,
			GridColumnsService,
			CROSS_APP_ACTIONS,
			CollapsedLinkedTasksService
		) {
			const SPACE = 32;
			const MODAL_CLOSED_EVENT = 'FLEET_ASSIGNMENT_MODAL_CLOSED';
			var dataView;
			var grid;
			var options = {
				explicitInitialization: true,
				headerRowHeight: 31,
				rowHeight: 31,
				autosizeColsMode: Slick.GridAutosizeColsMode.LegacyForceFit,
				editable: false,
				enableColumnReorder: false,
				enableAddRow: false,
				enableCellNavigation: true,
				forceSyncScrolling: true,
				showCheckbox: false,
				enableTextSelectionOnCells: true,
				multiSelect: true,
				multiColumnSort: false
			};
			$scope.isCollapsed = false;
			let onSelectedRowIdsChanged;

			$scope.compileAssignFleetField = function (taskId) {
				var element = document.querySelector('#list_task_' + taskId + '_fleet');
				element.removeAttribute('onclick');
				var overlayScope = $scope.$new(false);
				overlayScope.task = _.find($scope.data, function (task) {
					return task.id == taskId;
				});
				overlayScope.getGridSelectedItems = (function () {
					var selectedRows = $scope.selectedRows;
					return function () {
						return selectedRows;
					};
				})();
				$compile(element)(overlayScope);
			};
			$scope.selectActiveRow = $scope.selectActiveRow || false;
			$scope.selectedIdWithFiltered = [];

			function setCollapse(isCollapsed) {
				if (isCollapsed !== undefined) {
					$scope.isCollapsed = isCollapsed;
				} else {
					$scope.isCollapsed = !$scope.isCollapsed;
				}
			}

			$scope.onClickCollapse = function () {
				if ($scope.isCollapsed) {
					dataView.expandAllGroups();
				} else {
					dataView.collapseAllGroups();
				}

				syncMasterCheckbox();
			};

			angular.element($window).on('resize', function () {
				grid.resizeCanvas();
			});

			var groupItemMetadataProvider = new Slick.Data.GroupItemMetadataProvider();
			dataView = new Slick.Data.DataView({
				groupItemMetadataProvider: groupItemMetadataProvider,
				inlineFilters: false
			});

			dataView.onRowCountChanged.subscribe(function () {
				setCollapse(isAllGroupsCollapsed(dataView.getGroups()));
				grid.updateRowCount();
				grid.render();
				$scope.updateViewData();

				ScopeApplier.safeApply($scope);
			});

			function isAllGroupsCollapsed(groups) {
				const COLLAPSED = 1;

				return groups.every(group => {
					if (group.collapsed === COLLAPSED) {
						return true;
					}

					if (!group.groups) {
						return false;
					}

					return isAllGroupsCollapsed(group.groups);
				});
			}

			dataView.onRowsChanged.subscribe(function (e, args) {
				grid.invalidateRows(args.rows);
				grid.render();
				$scope.updateViewData();
			});

			var unsubscribeDataWatch = $scope.$watch('data', function (newData, oldData) {
				if (oldData !== newData) {
					grid.updateData(newData);
				}
			});

			if ($scope.getItemMetadata) {
				dataView.getItemMetadata = $scope.getItemMetadata(dataView.getItemMetadata);
			}

			var groupBy = function groupBy(value) {
				dataView.setGrouping(value);

				var $slick = $('.b-slick-grid');
				if ($slick.length > 0 && $('#collapse-expand').length == 0) {
					var div =
						'<div id="collapse-expand" ng-show="groupByValues"><span ng-click="onClickCollapse()" ng-class="{expanded: !isCollapsed}" id="collapse-icon"></span></div>';
					$slick.append(div);
					$compile(document.getElementById('collapse-expand'))($scope);
				}
				grid.render();
				$scope.updateViewData();
			};

			grid = new Slick.Grid($element.find('.b-slick-grid'), dataView, $scope.columns, options);

			var checkboxSelector = new Slick.CheckboxSelectColumn();

			grid.onHeaderClick.subscribe(event => {
				const jTarget = $(event.originalEvent.target);
				if (jTarget.attr('id') && jTarget.attr('id').startsWith('header-selector')) {
					dataView.expandAllGroups();
				}
			});

			grid.onKeyDown.subscribe(e => {
				if (e.which === SPACE) {
					e.stopImmediatePropagation();
				}
			});

			grid.setSelectionModel(new Slick.RowSelectionModel({ selectActiveRow: $scope.selectActiveRow }));
			grid.registerPlugin(checkboxSelector);
			grid.registerPlugin(groupItemMetadataProvider);

			var selectionChanged = function selectionChanged(event, data) {
				$scope.selectedIdWithFiltered = [...data.ids].filter(Boolean);
				syncViewWithSelection(data);
			};

			function renderTouchedGroups(changedIds) {
				const rowsToInvalidate = [];
				const changedTasks = changedIds.map(taskId => dataView.getItemById(taskId));
				const groupingInfos = dataView.getGrouping();
				let atLeastOneItemOutOfView = false;

				groupingInfos.forEach((groupingInfo, i) => {
					const allValues = new Set(changedTasks.map(task => task[groupingInfo.getter]));
					const groups = getGroupsByValue(i, allValues);
					for (const group of groups) {
						group.title = groupingInfo.formatter(group);

						const item = changedTasks.find(changedTask => {
							if (group.level === 1) {
								const parentGroup = dataView
									.getGroups()
									.find(mainGroup => mainGroup.groups.includes(group));
								return (
									changedTask[groupingInfo.getter] === group.value &&
									changedTask[groupingInfos[i - 1].getter] === parentGroup.value
								);
							}

							return changedTask[groupingInfo.getter] === group.value;
						});

						if (item) {
							const itemRow = dataView.getRowByItem(item);
							if (!itemRow) {
								atLeastOneItemOutOfView = true;
							} else {
								const groupRow = getGroupRow(itemRow, group.level);
								rowsToInvalidate.push(groupRow);
							}
						}
					}
				});

				if (atLeastOneItemOutOfView) {
					grid.invalidate();
				} else if (rowsToInvalidate.length > 0) {
					grid.invalidateRows(rowsToInvalidate);
					grid.render();
				}
			}

			function getGroupRow(startRow, groupLevel) {
				for (let i = startRow - 1; i >= 0; i--) {
					const item = dataView.getItem(i);

					if (item.__group && item.level === groupLevel) {
						return i;
					}
				}
			}

			function getGroupsByValue(level, values) {
				const LEVELS = [0, 1];

				if (!LEVELS.includes(level)) {
					return;
				}

				let groups = [];

				if (level === 0) {
					groups = dataView.getGroups();
				} else if (level === 1) {
					groups = _.flatMap(dataView.getGroups(), group => group.groups);
				}

				return groups.filter(group => values.has(group.value));
			}

			function syncMasterCheckbox() {
				var masterCheckbox = $('.slick-header-column .slick-column-name input');

				if (!masterCheckbox.length) {
					return;
				}

				if (
					$scope.selectedRows.length > 0 &&
					$scope.selectedRows.length === dataView.getFilteredItems().length
				) {
					masterCheckbox[0].checked = true;
					masterCheckbox.removeClass('master-no-selected');
					masterCheckbox.addClass('master-selected');
					masterCheckbox.removeClass('master-partial-selected');
				} else if ($scope.selectedRows.length === 0) {
					masterCheckbox[0].checked = false;
					masterCheckbox.addClass('master-no-selected');
					masterCheckbox.removeClass('master-selected');
					masterCheckbox.removeClass('master-partial-selected');
				} else {
					masterCheckbox[0].checked = false;
					masterCheckbox.removeClass('master-no-selected');
					masterCheckbox.removeClass('master-selected');
					masterCheckbox.addClass('master-partial-selected');
				}
			}

			grid.onHeaderCellRendered.subscribe(function () {
				syncMasterCheckbox();
			});

			function getCollapsedItems() {
				return $scope.selectedIdWithFiltered
					.filter(itemId => {
						return dataView.getRowById(itemId) === undefined;
					})
					.map(id => dataView.getItemById(id))
					.filter(Boolean);
			}

			function getSelectedItemsInView() {
				// keep the ones that are hidden
				const hiddenIds = getCollapsedItems();

				const idsFromView = dataView
					.mapRowsToIds(grid.getSelectedRows())
					.map(id => dataView.getItemById(id))
					.filter(Boolean);

				return hiddenIds.concat(idsFromView);
			}

			function getSelectedItems() {
				/**
				 * `fix_web_clustering_performance_in_selected_items` feature flag changes few things in this reducer:
				 * 1. It will look up for collapsed linked task only if the feature is enabled (wht `isCollapseLinkedTaskEnabled` method check)
				 * 2. It will never call `dataView.getItemById` when there is no collapse id
				 */
				return $scope.selectedItems.reduce((acc, item) => {
					const dataViewItem = dataView.getItemById(item.id);
					let isCollapseTaskParentOnUI = false;

					if (CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()) {
						const collapseId = CollapsedLinkedTasksService.getTaskIdToCollapseTaskId(item.id);
						isCollapseTaskParentOnUI = collapseId ? dataView.getItemById(collapseId) : undefined;
					}

					if (dataViewItem || isCollapseTaskParentOnUI) {
						acc.push(item);
					}

					return acc;
				}, []);
			}

			function updateSelectedItems() {
				const selectedItemsInDataView = getSelectedItems();

				if (CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()) {
					$scope.selectedItems.length = 0;
					selectedItemsInDataView.forEach(item =>
						$scope.selectedItems.push(...CollapsedLinkedTasksService.extractTasksFromCollapsedTasks([item]))
					);

					const selectedRowsSet = new Set();
					$scope.selectedRows.length = 0;
					selectedItemsInDataView.forEach(item => {
						let itemToPush = item;

						if (!dataView.getItemById(item.id)) {
							const parentId = CollapsedLinkedTasksService.getTaskIdToCollapseTaskId(item.id);
							const parentRow = dataView.getItemById(parentId);

							if (parentRow) {
								itemToPush = parentRow;
							}
						}

						if (!selectedRowsSet.has(itemToPush.id)) {
							$scope.selectedRows.push(itemToPush);
							selectedRowsSet.add(itemToPush.id);
						}
					});

					if ($scope.selectedRows.length) {
						selectRows($scope.selectedRows);
					}
				} else {
					$scope.selectedItems.length = 0;
					selectedItemsInDataView.forEach(item => $scope.selectedItems.push(item));

					$scope.selectedRows.length = 0;
					selectedItemsInDataView.forEach(item => $scope.selectedRows.push(item));
				}
			}

			function selectRows(selectedRows) {
				const rowsIdxToSelect = selectedRows.map(selectedRow => dataView.getRowByItem(selectedRow));
				grid.setSelectedRows(rowsIdxToSelect);
			}

			grid.onClick.subscribe(function (e, args) {
				const jTarget = $(e.target);

				if (jTarget.hasClass('group-select-all')) {
					const clickedGroup = dataView.getItem(args.row);
					if (clickedGroup) {
						dataView.beginUpdate();
						dataView.expandGroup(clickedGroup.groupingKey);
						if (clickedGroup.groups) {
							clickedGroup.groups.forEach(function (innerGroup) {
								dataView.expandGroup(innerGroup.groupingKey);
							});
						}
						dataView.endUpdate();
						var clickedGroupIndexes = _.map(clickedGroup.rows, function (item) {
							return dataView.getRowByItem(item);
						});

						if (jTarget.hasClass('selected')) {
							grid.setSelectedRows(_.difference(grid.getSelectedRows(), clickedGroupIndexes));
						} else {
							grid.setSelectedRows(_.union(clickedGroupIndexes, grid.getSelectedRows()));
						}
					}

					e.stopImmediatePropagation();
				}
			});
			/**
			 * store columns width plugin
			 */
			var useStoreColumnWidth = !_.isUndefined($attrs.storeColumnsWidth);

			if (useStoreColumnWidth) {
				var storageKey = 'slickGridColumns-' + ($attrs.storeColumnsWidth || $element[0].id);

				// on resize, store new width size
				grid.onColumnsResized.subscribe(function () {
					var columns = _.map(this.getColumns(), function (column) {
						return _.pick(column, 'id', 'width');
					});
					options.autosizeColsMode = Slick.GridAutosizeColsMode.None;

					// TODO: investigate why saved column width doesnt applied after preset changed
					// if (PresetViewsService.enabledForConfigKey($scope.gridId)) {
					// PresetViewsService.saveColumnsWidth($scope.gridId, columns);
					// } else {
					// save to local storage as before
					// }
					// store columns size in local storage
					localStorageService.set(storageKey, columns);
				});

				// add width from local storage if any matched
				var addStoredColumnsWidth = function (columns) {
					var storedColumnsWidth = localStorageService.get(storageKey) || [];

					_.each(columns, function (column) {
						// for each column, find the matching column width by id.
						var columnStoredWidth = _.find(storedColumnsWidth, { id: column.id }) || {};
						// set the width from stored value
						_.extend(column, columnStoredWidth);
					});
					return columns;
				};
			}

			/**
			 * end of store columns width plugin
			 */

			$scope.$watch('groupByValues', function (value) {
				if (value) {
					groupBy(value);
				} else {
					dataView.setGrouping([]);
				}

				dataView.refresh();

				if (onSelectedRowIdsChanged) {
					onSelectedRowIdsChanged.unsubscribe(selectionChanged);
				}
				onSelectedRowIdsChanged = dataView.syncGridSelection(grid, true, true);
				onSelectedRowIdsChanged.subscribe(selectionChanged);

				$scope.updateViewData();
			});

			const debouncedUpdateFilterFunctionForGrid = _.debounce(function () {
				dataView.setFilter(GridColumnsService.getFilterFuncForGrid($scope.grid));
			}, 500);

			// This is needed to keep filterFunction up-to-date with columns
			// otherwise free search will work only by columns present during onGridInited launch
			const unsubscribeColumnsWatch = $scope.$watch(
				function () {
					// '$scope.grid' most probably will have different fields as different controllers
					//   can write to this variable, 'ready' is set by planning / dispatch controller
					if ($scope.grid && $scope.grid.ready) {
						return $scope.grid.getColumns();
					}
					return null;
				},
				function () {
					if (dataView) {
						debouncedUpdateFilterFunctionForGrid();
					}
				}
			);

			// this solve the issue when multi columns updates are triggered at once
			var updateColumns = function (newColumns) {
				if (useStoreColumnWidth) {
					addStoredColumnsWidth(newColumns);
				}

				grid.setColumns(newColumns);
			};
			var debouncedUpdateColumns = _.debounce(updateColumns, 100);
			$scope.$watch('columns', debouncedUpdateColumns);

			/**
			 * grid save sorting
			 */

			var storeSortData = function (args) {
				if (!_.isEmpty($scope.gridId)) {
					var sortOptions = {
						id: args.sortCol.id,
						asc: args.sortAsc
					};

					SlickGridConfigService.saveSortData($scope.gridId, sortOptions);
				}
			};

			var loadStoredSortData = function () {
				if (!_.isEmpty($scope.gridId)) {
					var sortOptions = SlickGridConfigService.getSortData($scope.gridId);

					if (sortOptions) {
						var column = _.find($scope.columns, { id: sortOptions.id });
						if (column) {
							grid.setSortColumn(sortOptions.id, sortOptions.asc); //columnId, ascending
							sortByColumn(column, sortOptions.asc);
						}
					} else {
						// reset sorting on the table that was applied before
						grid.setSortColumns([]);
						// Reset data-layer sorting
						sortByColumn(null, true);
					}
				}
			};

			/**
			 * execute grid refresh data
			 */
			var debouncedRefreshData = null;
			$scope.grid.refreshData = function (newDataFunc, groupingType) {
				if (!debouncedRefreshData) {
					debouncedRefreshData = _.debounce(grid.updateData, $rootScope.tooManyEmployees ? 2000 : 100);
				}
				// This previously was triggered as reaction to PRESET_VIEWS_UPDATED cross-app event
				//  it may be overkill to launch it on any refreshData
				// loadStoredSortData();
				debouncedRefreshData($scope.data, true, newDataFunc, groupingType);
			};

			var syncViewWithSelection = function syncViewWithSelection(data) {
				const selectedIds = $scope.selectedRows
					.filter(item => dataView.getItemById(item.id))
					.map(({ id }) => id);

				const filteredItemsIds = new Set(dataView.getFilteredItems().map(item => item.id));
				const selectedFilteredIds = data.ids.filter(id => filteredItemsIds.has(id));

				const changedIds =
					selectedFilteredIds.length - selectedIds.length > 0
						? _.difference(selectedFilteredIds, selectedIds)
						: _.difference(selectedIds, selectedFilteredIds);

				if (angular.isDefined($scope.selectedRows)) {
					$scope.selectedItems.length = 0;
					$scope.selectedRows.length = 0;

					_.forEach(selectedFilteredIds, function (id) {
						var item = dataView.getItemById(id);
						if (_.isNil(item)) {
							return;
						}

						const locked = LockedTaskService.isLocked(item);
						const lockedByMe = LockedTaskService.isLockedByMe(item);

						if ((_.isNil(item.__group) && !locked) || (locked && lockedByMe)) {
							if (CollapsedLinkedTasksService.isCollapseLinkedTaskEnabled()) {
								const tasks = CollapsedLinkedTasksService.extractTasksFromCollapsedTasks([item]);
								$scope.selectedItems.push(...tasks);
							} else {
								$scope.selectedItems.push(item);
							}
							$scope.selectedRows.push(item);
						}
					});

					renderTouchedGroups(changedIds);
					syncMasterCheckbox();
				}

				if (angular.isDefined($scope.onSelection)) {
					$timeout(function () {
						$scope.onSelection();
					});
				}
			};

			$scope.grid.refreshFilters = function () {
				dataView.setFilterArgs($scope.filterObject);
				dataView.refresh();

				syncViewWithSelection({ ids: getSelectedItemsInView().map(item => item.id) });
				$scope.updateViewData();
				ScopeApplier.safeApply($scope);
			};

			$scope.$watch('sortFields', function (newData, oldData) {
				if (newData === oldData) {
					return;
				}
				dataView.sort(function (dataRow1, dataRow2) {
					for (var i = 0, l = newData.length; i < l; i++) {
						var field = newData[i];
						var value1 = dataRow1[field],
							value2 = dataRow2[field];
						var result = (value1 === value2 ? 0 : value1 > value2 ? 1 : -1) * 1;
						if (result !== 0) {
							return result;
						}
					}
					return 0;
				});
				grid.invalidate();
			});

			grid.updateData = function (currentData, doResort, newDataFunc, groupingType) {
				try {
					if (newDataFunc) {
						bringg_utils.setArrayItems(currentData, newDataFunc(groupingType));
					}

					dataView.setItems(currentData);
					updateSelectedItems();
					grid.invalidate();
					if (doResort) {
						dataView.reSort();
					}
					grid.updateRowCount();
					dataView.refresh();
					syncMasterCheckbox();
					$scope.updateViewData();
				} catch (e) {
					$log.error(e, currentData);
				}
			};
			$scope.grid.resizeCanvas = grid.resizeCanvas;
			$scope.grid.setColumns = grid.setColumns;
			$scope.grid.getColumns = grid.getColumns;
			$scope.grid.render = grid.render;
			$scope.grid.refresh = dataView.refresh;

			$scope.updateViewData = function () {
				$scope.grid.length = grid.getData().getFilteredItems().length;
				$scope.grid.groups = dataView.getGroups().length;
				if ($scope.filteredItems && $scope.filteredItems.values) {
					$scope.filteredItems.values = _.chain(dataView.getLength())
						.range()
						.map(function (idx) {
							return dataView.getItem(idx);
						})
						.difference(dataView.getGroups())
						.value();
				}
			};

			var getSortValue = function (item, column) {
				if (_.isFunction(column.getValue)) {
					if (column.id === 'priority') {
						return item.priority;
					}
					return column.getValue(item);
				}
				var value = bringg_utils.getNestedField(item, column.customSortField || column.field);
				var sortValue;
				if (value && value.hasOwnProperty('filterValue')) {
					sortValue = value.filterValue;
				} else if (value && angular.isArray(value)) {
					sortValue = value.sort().join('');
				} else if (value && value.hasOwnProperty('value')) {
					sortValue = value.value;
				} else if (value && value.hasOwnProperty('display')) {
					sortValue = value.display;
				} else {
					sortValue = value;
				}
				return sortValue;
			};

			$scope.gridSorter = function (column, isAsc, grid, dataView) {
				dataView.sort(function (dataRow1, dataRow2) {
					// column: null may be used to reset sorting
					if (!column) {
						return dataRow1.id > dataRow2.id ? 1 : -1;
					}

					var value1 = getSortValue(dataRow1, column);
					var value2 = getSortValue(dataRow2, column);

					// Both values are equal or if both are undefined/null treat them as equal.
					if (value1 === value2 || (value1 == null && value2 == null)) {
						return dataRow1.id > dataRow2.id ? 1 : -1;
					}

					// Only one of the values is undefined/null.
					if ((!value1 && value2 == null) || (!value2 && value1 == null)) {
						return value1 == null ? -1 : 1;
					}

					if (value1 && value2) {
						return value1 > value2 ? 1 : -1;
					}

					return !value1 ? -1 : 1;
				}, isAsc);

				grid.invalidate();
			};

			var sortByColumn = function (column, asc) {
				$scope.gridSorter(column, asc, grid, dataView);
			};

			grid.onSort.subscribe(function (e, args) {
				storeSortData(args);
				sortByColumn(args.sortCol, args.sortAsc);
			});

			dataView.beginUpdate();
			dataView.setItems($scope.data);
			dataView.setFilterArgs($scope.filterObject);
			if (_.isFunction($scope.filterFunction)) {
				dataView.setFilter($scope.filterFunction);
			}
			dataView.endUpdate();

			grid.init();
			loadStoredSortData();

			CrossApplicationService.on(MODAL_CLOSED_EVENT, function () {
				grid.setSelectedRows([]);
			});

			if (_.isFunction($scope.onInit)) {
				$scope.onInit(grid, dataView);
			}

			$scope.$on('$destroy', function () {
				debouncedRefreshData?.cancel();
				debouncedUpdateColumns?.cancel();

				unsubscribeDataWatch();
				unsubscribeColumnsWatch();
				CrossApplicationService.off(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, loadStoredSortData);
			});

			CrossApplicationService.on(CROSS_APP_ACTIONS.PRESET_VIEWS_UPDATED, loadStoredSortData);

			this.dataView = dataView;
			this.grid = grid;
		}
	);
