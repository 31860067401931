import { MarkerClusterer } from '@googlemaps/markerclusterer';

import { IdType } from '../../types';
import ClusterRenderer from './cluster-renderer';
import { getDataForMarker } from './markers-to-marker-data';

export const getCluster = (map: google.maps.Map, markerInstanceMap: Map<IdType, google.maps.Marker>) => {
	const markers: google.maps.Marker[] = [];

	markerInstanceMap.forEach(markerInstance => {
		const data = getDataForMarker(markerInstance);
		if (data && data.shouldCluster) {
			markers.push(markerInstance);
		}
	});

	return new MarkerClusterer({
		map,
		markers,
		renderer: new ClusterRenderer()
	});
};
