'use strict';

angular.module('bringgApp.filters', []);
angular.module('bringgApp.directives', []);
angular.module('bringgApp.services', []);
angular.module('bringgApp.widgets', []);

var app = angular
	.module('bringgApp', [
		'ngRoute',
		'ngResource',
		'ngAnimate',
		'ngCookies',
		'ngSanitize',
		'ngIdle',
		'chart.js',
		'ui.grid',
		'ui.map',
		'ui.event',
		'ui.bootstrap',
		'ui.select2',
		'ui.select',
		'ui.bootstrap.datetimepicker',
		'ui.bootstrap.modal',
		'puigcerber.capitalize',
		'angularFileUpload',
		'LocalStorageModule',
		'ng-context-menu',
		'colorpicker.module',
		'ngClipboard',
		'bootstrapLightbox',
		'angularResizable',
		'vcRecaptcha',
		'angular-svg-round-progressbar',
		'ui.grid',
		'ui.grid.grouping',
		'ui.grid.edit',
		'ui.grid.cellNav',
		'ui.grid.autoResize',
		'ui.grid.exporter',
		'ui.grid.moveColumns',
		'ui.grid.resizeColumns',
		'ui.grid.autoResize',
		'ui.grid.saveState',
		'uiSwitch',
		'bringgApp.services',
		'bringgApp.filters',
		'bringgApp.directives',
		'bringgApp.widgets',
		'serviceSetupService',
		'merchantLogsServices',
		'employeesTasksServices',
		'sharedProperties',
		'geolocationService',
		'pascalprecht.translate',
		'angular-dayparts',
		'frapontillo.gage',
		'ui.sortable',
		'daterangepicker',
		'ngMap',
		'selectize',
		'monospaced.qrcode',
		'tmh.dynamicLocale',
		'angular-click-outside'
	])
	.config(function (
		$routeProvider,
		$translateProvider,
		$translatePartialLoaderProvider,
		uiSelectConfig,
		$animateProvider,
		$httpProvider,
		tmhDynamicLocaleProvider,
		PRIVILEGES_TYPES,
		vcRecaptchaServiceProvider,
		RECAPTCHA_SITE_KEY
	) {
		tmhDynamicLocaleProvider.localeLocationPattern('node_modules/bower-angular-i18n/angular-locale_{{locale}}.js');
		$httpProvider.useApplyAsync(true);
		$animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);

		vcRecaptchaServiceProvider.setSiteKey(RECAPTCHA_SITE_KEY);
		vcRecaptchaServiceProvider.setTheme('light');

		const sharedAccessConfig = {
			teams: {
				or: [
					{
						featureFlag: 'enable_teams_v2_privilege',
						authorizationFlag: {
							or: [
								[PRIVILEGES_TYPES.TEAMS_V2, PRIVILEGES_TYPES.TEAMS_V2_EDIT],
								[PRIVILEGES_TYPES.TEAMS_V2, PRIVILEGES_TYPES.TEAMS_V2_VIEW]
							]
						}
					},
					{
						featureFlag: {
							not: true,
							name: 'enable_teams_v2_privilege'
						},
						authorizationFlag: PRIVILEGES_TYPES.TEAMS
					}
				]
			},
			driverProfile: {
				or: [
					{
						featureFlag: 'driver_profile_v2_privilege_enabled',
						authorizationFlag: {
							or: [
								[PRIVILEGES_TYPES.DRIVER_PROFILE_V2, PRIVILEGES_TYPES.DRIVER_PROFILE_V2_EDIT],
								[PRIVILEGES_TYPES.DRIVER_PROFILE_V2, PRIVILEGES_TYPES.DRIVER_PROFILE_V2_VIEW]
							]
						}
					},
					{
						featureFlag: {
							not: true,
							name: 'driver_profile_v2_privilege_enabled'
						},
						authorizationFlag: PRIVILEGES_TYPES.DRIVER_PRORFILE
					}
				]
			}
		};

		$routeProvider
			.when('/oauth/', {
				templateUrl: 'scripts/features/oauth/o-auth.html',
				controller: 'oAuthController'
			})
			.when('/sso/saml', {
				title: 'SAML Login',
				templateUrl: 'scripts/features/sso/sso.html',
				controller: 'ssoController'
			})
			.when('/remind/', {
				title: 'Remind Password',
				templateUrl: 'scripts/features/remind/password-reminder.html',
				controller: 'clientReminderController'
			})
			.when('/reset/', {
				title: 'Reset Password',
				templateUrl: 'scripts/features/password_reset/password-reset.html',
				controller: 'passwordResetController'
			})
			.when('/login/', {
				title: 'Login',
				templateUrl: 'scripts/features/login/login.html',
				controller: 'clientLoginController'
			})
			.when('/logout/', {
				template: '',
				controller: 'clientLogoutController'
			})
			.when('/change_password/', {
				title: 'Change Password',
				templateUrl: 'scripts/features/force_change_password/change_password.html',
				controller: 'changePasswordController'
			})
			.when('/enforce_password_update/', {
				title: 'Password Update ',
				templateUrl: 'scripts/features/enforce_password_update/enforce-password-update.html',
				controller: 'enforcePasswordUpdateController'
			})
			.when('/confirmation', {
				title: 'Confirm Email',
				templateUrl: 'scripts/features/email_confirmation/client-confirmation.html',
				controller: 'clientConfirmationController'
			})
			// If the page need to be under Authorization - make sure at least `access: {}` is present in config
			.when('/merchant/setup/', {
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				},
				reloadOnSearch: false
			})
			.when('/merchant/', {
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				supportSectionId: 360007489952,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.ENABLE_SETTING_PAGE
				}
			})
			.when('/merchant/settings/', {
				title: 'Merchant Settings',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/users/', {
				title: 'Users Settings',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/customer-notifications/', {
				title: 'Customer Notifications',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/account-manager/', {
				title: 'Account Manager',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/alerts-config/', {
				title: 'Alerts Configuration',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/automations/', {
				title: 'Automations',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {},
				// Not reloading on search as when we filter the automation and update the query params
				// we don't want to reload
				reloadOnSearch: false
			})
			.when('/merchant/automations/:id', {
				title: 'Automations',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {}
			})
			.when('/merchant/payments/', {
				title: 'Payments',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/customer-app/', {
				title: 'Customer App Configurations',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/driver-app/', {
				title: 'Driver App Configurations',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/billing/', {
				title: 'Billing',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/merchant-config/', {
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/webhooks/', {
				title: 'Webhooks Configurations',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {}
			})
			.when('/merchant/fleets-configuration/', {
				title: 'Fleets Configuration',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {}
			})
			.when('/merchant/delivery-blocks-templates/', {
				title: 'Delivery Blocks',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: { adminRequired: true }
			})
			.when('/merchant/optimizations/', {
				title: 'Optimizations',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: { adminRequired: true },
				// Not reloading on search as when we filter the optimizations and update the query params
				// we don't want to reload
				reloadOnSearch: false
			})
			.when('/merchant/delivery-blocks-templates/new', {
				title: 'Delivery Blocks',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: { adminRequired: true }
			})
			.when('/merchant/delivery-blocks-templates/:templateId?', {
				title: 'Delivery Blocks',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: { adminRequired: true }
			})
			.when('/delivery-catalog-connect/:providerId?', {
				title: 'My Delivery Providers',
				templateUrl: 'scripts/features/delivery_catalog_connect/delivery-catalog-connect.html',
				access: {}
			})
			.when('/fleets-rule-list', {
				title: 'Fleet Router',
				templateUrl: 'scripts/features/fleets_rule_list/fleets-rule-list.html',
				access: {}
			})
			.when('/delivery-catalog', {
				title: 'Discover Delivery Providers',
				templateUrl: 'scripts/features/delivery_catalog/delivery-catalog.html',
				access: {}
			})
			.when('/merchant/store-app/', {
				title: 'Store App Configurations',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {}
			})
			.when('/merchant/developer/', {
				title: 'Developer Credentials',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/applications/', {
				title: 'Applications',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/tags/', {
				title: 'Tags Management',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/logs/', {
				title: 'Logs',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.ENABLE_MERCHANT_LOGS
				}
			})
			.when('/merchant/notifications/', {
				title: 'Customer Notifications Configurations',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/orders/:id/', {
				title: 'Task',
				templateUrl: 'scripts/features/task_view/task.html',
				controller: 'TaskController',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.VIEW_TASK
				}
			})
			.when('/history/:id/', {
				title: 'Task',
				templateUrl: 'scripts/features/task_view/task.html',
				controller: 'TaskController',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.VIEW_TASK
				}
			})
			.when('/alerts/', {
				title: 'Alerts',
				templateUrl: 'scripts/features/alerts_view/alerts.html',
				controller: 'AlertsController',
				access: {}
			})
			.when('/list/', {
				title: 'Dispatch List',
				templateUrl: 'scripts/features/dispatch/list/dispatch-list.html',
				controller: 'DispatchListController',
				enableAckWindow: true,
				supportSectionId: 360007438092,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				}
			})
			.when('/dispatcher-list-print/', {
				templateUrl: 'scripts/features/dispatcher_print/dispatcher-print.html',
				controller: 'DispatcherPrintController',
				access: {}
			})
			.when('/planning/', {
				title: 'Planning',
				templateUrl: 'scripts/features/planning_tab/planning-tab.html',
				controller: 'PlanningTabController',
				supportSectionId: 360007438112,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				}
			})
			.when('/map/', {
				title: 'Dispatch Map',
				templateUrl: 'scripts/features/dispatch/map/map.html',
				controller: 'PresentController',
				reloadOnSearch: false,
				enableAckWindow: true,
				supportSectionId: 360007438092,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				}
			})
			.when('/present/', {
				templateUrl: 'scripts/features/dispatch/present.html',
				controller: 'PresentController',
				reloadOnSearch: false,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				}
			})
			.when('/customers/:id/tasks', {
				title: 'Customer Tasks',
				templateUrl: 'scripts/features/customer_tasks/customer-tasks.html',
				controller: 'CustomerTasksController',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.CUSTOMERS
				}
			})
			.when('/customers/:id', {
				title: 'Customer',
				templateUrl: 'scripts/features/customer/customer.html',
				controller: 'CustomerController',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.CUSTOMERS
				}
			})
			.when('/orders/', {
				title: 'History',
				templateUrl: 'scripts/features/tasks/all_tasks/all.html',
				controller: 'AllTasksController',
				access: {}
			})
			.when('/history/', {
				title: 'History',
				templateUrl: 'scripts/features/tasks/all_tasks/all.html',
				controller: 'AllTasksController',
				supportSectionId: 360007529251,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.HISTORY
				}
			})
			.when('/customers/', {
				title: 'Customers',
				templateUrl: 'scripts/features/customer/all-customers.html',
				controller: 'AllCustomersController',
				supportSectionId: 360007438152,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.CUSTOMERS
				}
			})
			.when('/reports/', {
				title: 'Reports',
				templateUrl: 'scripts/features/reports/reports.html',
				controller: 'reportsController',
				reloadOnSearch: false,
				supportSectionId: 360007581191,
				access: {
					merchantCreatedBefore: '2022-08-20'
				}
			})
			.when('/new_reports/', {
				title: 'Reports',
				templateUrl: 'scripts/features/reports_new/reports.html',
				controller: 'reports2Controller',
				reloadOnSearch: false,
				supportSectionId: 360007330952,
				access: {
					merchantCreatedBefore: '2022-08-20'
				}
			})
			.when('/drivers_report/', {
				title: 'Reports',
				templateUrl: 'scripts/features/reports_new/reports.html',
				controller: 'reports2Controller',
				reloadOnSearch: false
			})
			.when('/drivers/teams', {
				title: 'Teams',
				templateUrl: 'scripts/features/team/all-teams.html',
				controller: 'AllTeamsController',
				access: sharedAccessConfig.teams
			})
			.when('/drivers/teams/:id', {
				title: 'Team',
				access: sharedAccessConfig.teams
			})
			.when('/drivers/teams/:id/info', {
				title: 'Info',
				access: sharedAccessConfig.teams
			})
			.when('/drivers/teams/:id/vehicles', {
				title: 'Vehicles',
				access: sharedAccessConfig.teams
			})
			.when('/drivers/teams/:id/drivers', {
				title: 'Drivers',
				access: sharedAccessConfig.teams
			})
			.when('/drivers/teams/:id/service-areas', {
				title: 'Service areas',
				access: sharedAccessConfig.teams
			})
			.when('/drivers/teams/:id/service-areas/:teamServiceAreaId', {
				title: 'Team Service area',
				access: sharedAccessConfig.teams
			})
			.when('/drivers/teams/:id/settings', {
				title: 'Settings',
				access: sharedAccessConfig.teams
			})
			.when('/drivers/user-types/', {
				title: 'User Types',
				access: {}
			})
			.when('/drivers/privileges/', {
				title: 'Privileges',
				templateUrl: 'scripts/features/privileges/all-privileges.html',
				controller: 'AllPrivilegesController',
				access: {
					adminRequired: true
				}
			})
			.when('/drivers/privileges/:id', {
				title: 'Privilege',
				templateUrl: 'scripts/features/privileges/privilege.html',
				controller: 'PrivilegeController',
				access: {
					adminRequired: true
				}
			})
			.when('/drivers/user-types/:id', {
				title: 'User Type',
				templateUrl: 'scripts/features/user_types/user-type.html',
				controller: 'UserTypeController',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.USER_TYPES
				}
			})
			.when('/drivers/:id/cash_out', {
				title: 'Cash Out',
				templateUrl: 'scripts/features/cash_out/cash-out.html',
				controller: 'CashOutController',
				access: {}
			})
			//==============================================
			// Driver
			//==============================================
			.when('/drivers/', {
				title: 'Drivers',
				templateUrl: 'scripts/features/users/all_drivers/all-drivers.html',
				controller: 'AllDriversController',
				supportSectionId: 360007438132,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DRIVERS
				}
			})
			.when('/drivers/:id/tasks', {
				title: 'Driver Tasks',
				templateUrl: 'views/employee/tasks.html',
				controller: 'EmployeeTasksController',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.HISTORY
				}
			})
			.when('/drivers/:id/shifts', {
				title: 'Driver Shifts',
				templateUrl: 'views/employee/shifts.html',
				controller: 'EmployeeShiftsController',
				access: sharedAccessConfig.driverProfile
			})
			.when('/drivers/crews', {
				title: 'Crews page',
				access: {}
			})
			.when('/drivers/:id', {
				title: 'Driver',
				templateUrl: 'scripts/features/users/show_driver/show.html',
				controller: 'EmployeeController',
				access: sharedAccessConfig.driverProfile
			})
			.when('/demo/', {
				title: 'Demo',
				templateUrl: 'scripts/features/demo/demo.html',
				controller: 'DemoController',
				reloadOnSearch: false
			})
			.when('/dispatcher-alerts/', {
				title: 'Dispatcher Alerts',
				templateUrl: 'views/dispatcher-alerts.html',
				access: {}
			})
			.when('/connect/stripe/', {
				templateUrl: 'scripts/features/connect_to_stripe/connect-to-stripe.html',
				controller: 'ConnectToStripeController',
				access: {}
			})
			.when('/big-map/', {
				title: 'Big Map',
				templateUrl: 'scripts/features/big_map/big-map.html',
				controller: 'BigMapController',
				access: {}
			})
			.when('/big-map-analysis/:id', {
				title: 'Big Map Analysis',
				templateUrl: 'scripts/features/big_map_analysis/big-map-analysis.html',
				controller: 'BigMapAnalysisController',
				access: {}
			})
			.when('/inventory-management/', {
				title: 'Inventory Management',
				templateUrl: 'scripts/features/inventory/inventory-management.html',
				controller: 'InventoryManagementController',
				access: {}
			})
			.when('/support/', {
				title: 'Customer Care',
				templateUrl: 'scripts/features/customer_care/customer-care.html',
				controller: 'CustomerCareController',
				supportSectionId: 360007580891,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.CUSTOMER_CARE
				}
			})
			.when('/request_dispatch/', {
				title: 'Request Dispatch',
				templateUrl: 'scripts/features/request_dispatch/request-dispatch.html',
				controller: 'RequestDispatchController',
				access: {
					authorizationFlag: 'request_dispatch'
				}
			})
			.when('/kds', {
				title: 'KDS',
				templateUrl: 'scripts/features/kds/kds.html',
				controller: 'KdsController',
				enableAckWindow: true,
				supportSectionId: 360007438052,
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				}
			})
			// React Route
			.when('/delivery-blocks-v2', {
				title: 'New Delivery Blocks',
				access: {}
			})
			.when('/planned-delivery-windows', {
				title: 'Planned Delivery Windows',
				access: {
					authorizationFlag: 'planned_delivery_windows'
				}
			})
			.when('/floating-inventories-monitor', {
				title: 'Floating Inventory',
				access: {}
			})
			.when('/runs/:id', {
				title: 'Run',
				access: {}
			})
			.when('/runs', {
				title: 'Runs',
				access: {}
			})
			.when('/runs-monitoring', {
				title: 'Route Monitor',
				templateUrl: 'scripts/features/routes_monitoring/routes-monitoring.html',
				access: {},
				reloadOnSearch: false
			})
			.when('/planning/order-details', {
				title: 'Planning | Order Manager',
				templateUrl: 'scripts/features/routes_monitoring/routes-monitoring.html',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				},
				reloadOnSearch: false
			})
			.when('/planning/runs-monitoring', {
				title: 'Planning | Route Monitor',
				templateUrl: 'scripts/features/routes_monitoring/routes-monitoring.html',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				},
				reloadOnSearch: false
			})

			.when('/dispatch/runs-monitoring', {
				title: 'Dispatch | Route Monitor',
				templateUrl: 'scripts/features/routes_monitoring/routes-monitoring.html',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				},
				reloadOnSearch: false
			})
			.when('/dispatch/order-details', {
				title: 'Dispatch | Order Manager',
				templateUrl: 'scripts/features/routes_monitoring/routes-monitoring.html',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.DISPATCH_SCREEN
				},
				reloadOnSearch: false
			})
			.when('/vehicle/:id', {
				title: 'Vehicle Assignment History',
				access: {}
			})
			.when('/chat', {
				title: 'Chat',
				access: {}
			})
			.when('/chat_v2', {
				// SENDBIRD_CHAT_ROUTE_PATH
				title: 'Chat',
				access: {}
			})
			.when('/companies', {
				title: 'Companies',
				access: {}
			})
			.when('/companies/:id', {
				title: 'Company',
				access: {}
			})
			.when('/billing', {
				title: 'Billing',
				templateUrl: 'scripts/features/billing/billing.html',
				controller: 'BillingController',
				access: {
					adminOrDispatcherRequired: true,
					authorizationFlag: PRIVILEGES_TYPES.BILLING_PORTAL
				}
			})
			.when('/billing/:childrenRoutes*', {
				title: 'Billing',
				templateUrl: 'scripts/features/billing/billing.html',
				controller: 'BillingController',
				access: {
					adminOrDispatcherRequired: true,
					authorizationFlag: PRIVILEGES_TYPES.BILLING_PORTAL
				}
			})
			.when('/analytics/reports', {
				template: ' ',
				controller: 'AnalyticsReportsRootController',
				access: {}
			})
			.when('/analytics/reports/:path*', {
				title: 'Reports',
				templateUrl: 'scripts/features/analytics_reports/analytics-reports.html',
				controller: 'AnalyticsReportsController',
				reloadOnSearch: false,
				access: {
					adminOrDispatcherRequired: true,
					authorizationFlag: PRIVILEGES_TYPES.REPORTS
				}
			})
			.when('/analytics/dashboards/:id?', {
				title: 'Dashboards',
				templateUrl: 'scripts/features/analytics_dashboards/analytics-dashboards.html',
				controller: 'AnalyticsDashboardsController',
				reloadOnSearch: false,
				access: {
					adminOrDispatcherRequired: true
				}
			})
			.when('/fleet-router', {
				title: 'Fleet Router',
				access: {
					authorizationFlag: PRIVILEGES_TYPES.ENABLE_FLEET_ROUTER
				}
			})
			.when('/merchant/configurations-audit/', {
				title: 'Configurations Audit',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/merchant/service-areas', {
				title: 'Service Area',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {},
				reloadOnSearch: false
			})
			.when('/merchant/service-areas/traffic/:id', {
				title: 'Service Area Traffic',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {}
			})
			.when('/merchant/optimization-configuration/', {
				title: 'Optimization Configuration',
				templateUrl: 'views/merchant.html',
				controller: 'MerchantController',
				access: {
					adminRequired: true
				}
			})
			.when('/addons/', {
				title: 'Accelerators',
				templateUrl: 'scripts/features/addons/addons.html',
				access: {
					adminOrDispatcherRequired: true
				}
			})
			.when('/vehicles-manager', {
				title: 'Vehicle Manager',
				templateUrl: 'scripts/features/vehicles-manager/vehicles-manager.html',
				access: {
					authorizationFlag: 'new_vehicles_page'
				}
			})
			.when('/vehicles-manager/team/:teamId', {
				title: 'Vehicle',
				templateUrl: 'scripts/features/vehicles-manager/vehicles-manager.html',
				access: {
					authorizationFlag: 'new_vehicles_page'
				}
			})
			.when('/vehicles-manager/:vehicleId', {
				title: 'Vehicle',
				templateUrl: 'scripts/features/vehicles-manager/vehicles-manager.html',
				access: {
					authorizationFlag: 'new_vehicles_page'
				}
			})
			.otherwise({
				redirectTo: '/login/'
			});

		$translateProvider
			.useLoader('$translatePartialLoader', {
				urlTemplate: 'i18n/{lang}.json',
				$http: {
					headers: {
						// strip authorization as it might cause issues https://bringg.atlassian.net/browse/BRNGG-20158
						Authorization: undefined
					}
				}
			})
			.preferredLanguage('en');
		$translateProvider.fallbackLanguage('en');
		$translateProvider.useLoaderCache('$templateCache');
		$translateProvider.useSanitizeValueStrategy('escape');
		$translatePartialLoaderProvider.addPart('main');

		uiSelectConfig.theme = 'select2';
		uiSelectConfig.resetSearchInput = true;
	})
	.run(function (toastr, $log, localStorageService, $rootScope, $window, appReadyIndicator, $location) {
		appReadyIndicator.init();
		toastr.options.positionClass = 'toast-bottom-right';

		$log.enable(localStorageService.get('logEnabled'));

		$rootScope.$on('$routeChangeSuccess', function (event, current) {
			$window.document.title =
				current.$$route && current.$$route.title ? 'Bringg | ' + current.$$route.title : 'Bringg Management';
		});

		var initRoute = localStorageService.get('init_route');
		if (initRoute) {
			localStorageService.remove('init_route');
			$location.path(initRoute);
		}
	})
	.run(function ($rootScope, $location, $timeout, authorization, MainNavigationVer2Service) {
		$rootScope.$on('$routeChangeStart', function (event, next) {
			if (next.access !== undefined || next.params.authentication_token) {
				next.resolve = next.resolve || {};
				if (!next.resolve.authorizationResolver) {
					next.resolve.authorizationResolver = function () {
						return authorization.authorize(next.access);
					};
				}
			}
		});

		$rootScope.$on('$routeChangeError', function (event, to, from, error) {
			if (error instanceof authorization.AuthorizationError) {
				//try find available
				var availableViewLocation = MainNavigationVer2Service.getFirstAvailableViewLocation();

				if (availableViewLocation) {
					var prevPage = window.location.href;
					window.history.back();
					$timeout(function () {
						if (window.location.href == prevPage) {
							$location.path(availableViewLocation);
						}
					}, 100);
				} else {
					// go logout
					var returnPath = $location.path();

					// At the moment only billing and analytics/reports pages should support rerouting after login
					if (returnPath === '/billing' || returnPath.startsWith('/analytics/')) {
						// Just sync call $location.search('next_url', returnPath) under `if` statement
						// trigger route change listeners which causing too much $digest cycles
						// that's why we have code `$timeout($location.path('/logout/')` duplicated
						$timeout($location.path('/logout/').search('next_url', returnPath));
					}

					$timeout($location.path('/logout/'));
				}
			}
		});
	});

app.value('toastr', toastr);
