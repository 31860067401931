import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PlannedRoute, Run } from '@bringg/types';
import { Modal } from '@bringg/react-components';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { withErrorBoundary } from '@bringg-frontend/bringg-web-infra';

import { useStores } from '../../../recipes';
import RunEditor from '../../runs-assignment/run-editor';

import './run-editor-modal.scss';

interface RunEditorProps {
	plannedRoutes: PlannedRoute[];
	source: {
		run: Run;
	};
	onUpdate?: () => void;
	isOverlappedByScheduledTimeRange: (runId: number, plannedRouteId: number) => boolean;
	onClose: () => void;
}

const RunEditorModal = ({
	plannedRoutes,
	source,
	onUpdate,
	isOverlappedByScheduledTimeRange,
	onClose
}: RunEditorProps) => {
	const [plannedRouteId, setPlannedRouteId] = useState<number>();
	const [errorVisibility, setErrorVisiblity] = useState<boolean>(false);
	const [errorElement, setErrorElement] = useState<JSX.Element>(null);
	const { runStore } = useStores();
	const { t } = useTranslation();

	useEffect(() => {
		setErrorElement(errorVisibility ? errorMessageElement : null);
	}, [errorVisibility]);

	const errorMessageElement = (
		<div className="run-editor-modal-error-container">
			<BringgIcon iconName={BringgFontIcons.Warning} className="run-editor-modal-error-icon" />
			<span className="run-editor-modal-error-message">{t('RUN_EDITOR.ERROR')}</span>
		</div>
	);

	const onRunParamsChanged = (run: Partial<Run>) => {
		setPlannedRouteId(run.planned_route_id);
		setErrorVisiblity(false);
	};

	const onModalClose = (wasUpdate?: boolean) => {
		if (wasUpdate) {
			onUpdate?.();
		}
		onClose();
	};

	const onOk = async () => {
		if (source.run.planned_route_id !== plannedRouteId) {
			try {
				await runStore.update(source.run.id, { planned_route_id: plannedRouteId });
				onModalClose(true);
			} catch (e) {
				setErrorVisiblity(true);
				console.error(e);
			}
		} else {
			onModalClose();
		}
	};

	return (
		<Modal visible afterClose={onModalClose} onCancel={() => onModalClose()} onOk={onOk}>
			{errorVisibility && (
				<span className="run-editor-modal-error-message">{t('RUN_EDITOR.FAILED_TO_UPDATE_RUN')}</span>
			)}
			<RunEditor
				plannedRoutes={plannedRoutes}
				source={source}
				title={t('RUN_EDITOR.TITLE')}
				subtitle={t('RUN_EDITOR.SUBTITLE')}
				dateRange={{
					startTime: new Date(source.run.scheduled_start_time),
					endTime: new Date(source.run.scheduled_end_time),
					disabled: true
				}}
				onRunParamsChanged={onRunParamsChanged}
				errorElement={errorElement}
				isOverlappedByScheduledTimeRange={isOverlappedByScheduledTimeRange}
			/>
		</Modal>
	);
};
export default withErrorBoundary(RunEditorModal);
