import React, { useState } from 'react';

import classNames from 'classnames';

import BringgGoogleMapProvider from './google-map-provider/bringg-google-map-provider';
import useRoutePlayer from './bringg-map-route-player/custom-hooks/bringg-map-route-player-hooks/use-route-player';

import { BringgMapPropsV2, BringgMapRoutePlayerContainer, IdType } from './index';

const BringgMap = ({
	markers,
	mapCenter,
	mapZoom,
	maxZoom,
	minZoom,
	polylines,
	showPlayer,
	disablePlayer,
	timeZone,
	timeFormat,
	mapElementClassName,
	mapContainerClassName,
	translations = {},
	fullscreenControl,
	allowTrafficLayer,
	buttons,
	forceRecenterOnAddingMarkers,
	forceRecenterOnUpdatingMarkers,
	'data-test-id': dataTestId,
	selectedMarkers,
	onMarkerClick,
	clearSelectedMarkers,
	onMapClick,
	boundsPadding,
	options,
	cluster
}: BringgMapPropsV2) => {
	const hasRoutePath = !!(polylines?.length && polylines[0].path?.length > 1);
	const [isPlaying, setIsPlaying] = useState(false);

	const {
		setRoutePlayerDriverLocation,
		markersWithRoutePlayerDriverMarker,
		hasRealtimeDriverLocation,
		playerDisabled
		//@ts-ignore
	} = useRoutePlayer({ markers, disablePlayer, hasRoutePath });

	const onSelectMarker = (isCtrlClick: boolean, markerId: IdType) => {
		onMarkerClick?.(markerId, isCtrlClick);
	};

	return (
		<div className={classNames(mapContainerClassName)} data-test-id={dataTestId}>
			<BringgGoogleMapProvider
				markers={markersWithRoutePlayerDriverMarker || markers}
				mapCenter={mapCenter}
				mapZoom={mapZoom}
				maxZoom={maxZoom}
				minZoom={minZoom}
				polylines={polylines}
				mapElementClassName={mapElementClassName}
				fullscreenControl={fullscreenControl}
				allowTrafficLayer={allowTrafficLayer}
				translations={translations}
				buttons={buttons}
				isPlaying={isPlaying}
				forceRecenterOnAddingMarkers={forceRecenterOnAddingMarkers}
				forceRecenterOnUpdatingMarkers={forceRecenterOnUpdatingMarkers}
				onSelectMarker={onSelectMarker}
				selectedMarkers={selectedMarkers}
				clearSelectedMarkers={clearSelectedMarkers}
				onMapClick={onMapClick}
				boundsPadding={boundsPadding}
				options={options}
				cluster={cluster}
			/>
			{showPlayer && (
				<BringgMapRoutePlayerContainer
					initialRouteLocations={hasRoutePath ? polylines[0].path : []}
					disablePlayer={!!playerDisabled}
					setRoutePlayerDriverLocation={setRoutePlayerDriverLocation}
					hasRealtimeDriverLocation={!!hasRealtimeDriverLocation}
					timeZone={timeZone}
					timeFormat={timeFormat}
					translations={translations}
					onTogglePlaying={isPLaying => {
						setIsPlaying(isPLaying);
					}}
				/>
			)}
		</div>
	);
};
export default BringgMap;
