import { LatLng, Location as BringgLocation } from '@bringg/types';

import {
	Anchor,
	defaultAnchor,
	ExtendedMarkerData,
	ExtendedPolylineData,
	Size,
	IdType,
	TriggerType
} from '../../types';
import { isLatLngValid } from '../../utils';
import { UPDATE_DRIVER_LOCATION_THROTTLE_MS } from '../../bringg-map-route-player/consts';
import { getDataForMarker } from './markers-to-marker-data';

export const MARKER_REPOSITION_ANIMATION_DISTANCE_LIMIT = 0.01;
export const MARKER_REPOSITION_ANIMATION_DELTA = 50;
export const MARKER_REPOSITION_ANIMATION_DELAY = UPDATE_DRIVER_LOCATION_THROTTLE_MS / MARKER_REPOSITION_ANIMATION_DELTA;
export const DEFAULT_MAX_ZOOM = 19;
export const DEFAULT_MIN_ZOOM = 4;
export const DEFAULT_AUTOFOCUS_ZOOM = 15;
const DEFAULT_LABEL_COLOR = 'white';
const DEFAULT_CUSTOM_MARKER_SIZE = 35;
const DEFAULT_CUSTOM_MARKER_ZINDEX = 99;
const DEFAULT_ICON_HEIGHT = 20;
const DEFAULT_ICON_WIDTH = 26;
const DEFAULT_ICON_SCALE = 1.6;
export const SELECTED_ICON_SCALE = 2;

const DEFAULT_ICON_SVG_PATH =
	'M20.5 8.14815C20.5 9.1422 20.0517 10.3533 19.3151 11.6518C18.5854 12.938 17.6058 14.2534 16.6159 15.4416C15.6274 16.6279 14.6376 17.6768 13.8942 18.4297C13.5241 18.8044 13.2158 19.1052 13 19.3122C12.7842 19.1052 12.4759 18.8044 12.1058 18.4297C11.3624 17.6768 10.3726 16.6279 9.38414 15.4416C8.39416 14.2534 7.41456 12.938 6.68489 11.6518C5.9483 10.3533 5.5 9.1422 5.5 8.14815C5.5 3.9155 8.86647 0.5 13 0.5C17.1335 0.5 20.5 3.9155 20.5 8.14815Z';

export const halfOfDefaultMinMaxZoomRange = Math.round((DEFAULT_MAX_ZOOM - DEFAULT_MIN_ZOOM) / 2);

export const addGoogleMarker = ({
	map,
	markerData: initialMarkerData,
	infoWindow,
	onSelectMarker,
	selectedMarkers,
	hideAnimation,
	shouldOpenTooltipOnCreation
}: {
	map: google.maps.Map;
	markerData: ExtendedMarkerData;
	infoWindow: google.maps.InfoWindow;
	onSelectMarker?: (isCtrlClick: boolean, markerId: IdType) => void;
	selectedMarkers?: IdType[];
	hideAnimation?: boolean;
	shouldOpenTooltipOnCreation?: boolean;
}) => {
	if (!map || !isLatLngValid(initialMarkerData?.location)) return;
	let zIndex = initialMarkerData.zIndex || DEFAULT_CUSTOM_MARKER_ZINDEX;
	if (selectedMarkers?.length && selectedMarkers.includes(initialMarkerData.id)) {
		zIndex = google.maps.Marker.MAX_ZINDEX + (zIndex || 0);
	}

	const markerOptions: google.maps.MarkerOptions = {
		map,
		position: new google.maps.LatLng(initialMarkerData.location.lat, initialMarkerData.location.lng),
		zIndex: zIndex,
		animation: google.maps.Animation.DROP,
		opacity: initialMarkerData.opacity || 1,
		title: initialMarkerData.title
	};

	if (initialMarkerData.text) {
		markerOptions['label'] = {
			text: initialMarkerData.text,
			color: initialMarkerData.textColor || DEFAULT_LABEL_COLOR
		};
	}

	if (initialMarkerData.animation !== undefined) {
		markerOptions['animation'] = initialMarkerData.animation;
	}

	if (initialMarkerData.icon) {
		const size =
			!initialMarkerData.size?.height || !initialMarkerData.size?.width
				? new google.maps.Size(DEFAULT_CUSTOM_MARKER_SIZE, DEFAULT_CUSTOM_MARKER_SIZE)
				: new google.maps.Size(initialMarkerData.size.width, initialMarkerData.size.height);

		const { x: anchorX, y: anchorY } = convertIconAnchorToOffset('center', size);
		const anchor = new google.maps.Point(anchorX, anchorY);

		markerOptions['icon'] = {
			url: initialMarkerData.icon as string,
			anchor,
			size,
			scaledSize: size
		};
	} else if (initialMarkerData.plainSvg) {
		let svgContent;
		if (initialMarkerData.plainSvg.trimLeft().startsWith('<svg')) {
			svgContent = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(initialMarkerData.plainSvg);
		} else {
			throw new Error('plainSvg should start with <svg tag');
		}
		markerOptions['icon'] = {
			url: svgContent
		};
	} else if (initialMarkerData.color) {
		if (selectedMarkers && selectedMarkers.length >= 1 && selectedMarkers.includes(initialMarkerData.id)) {
			markerOptions['icon'] = getSelectedIcon(initialMarkerData.color);
			markerOptions.animation = undefined;
		} else {
			markerOptions['icon'] = getDefaultIcon(initialMarkerData.color);
		}
		if (hideAnimation) {
			markerOptions.animation = undefined;
		}
	}

	const marker = new google.maps.Marker(markerOptions);

	if (initialMarkerData.tooltip) {
		const trigger = initialMarkerData.tooltipOptions?.trigger || TriggerType.MOUSEOVER;

		if (shouldOpenTooltipOnCreation) {
			initialMarkerData.tooltip && infoWindow.setContent(initialMarkerData.tooltip);
			infoWindow.open(map, marker);
		}

		const markerTriggerListener = google.maps.event.addListener(marker, trigger, (event: any) => {
			// Not using the initialMarkerData as the marker data reference can be changed while the marker instance stayed the same
			const markerData = getDataForMarker(marker);

			if (!markerData) {
				markerTriggerListener.remove();
				return;
			}

			infoWindow.close();
			markerData.tooltip && infoWindow.setContent(markerData.tooltip);
			infoWindow.open(map, marker);

			const ctrlKey = event.domEvent.metaKey || event.domEvent.ctrlKey;
			onSelectMarker?.(ctrlKey, markerData.id);
		});

		if (trigger === TriggerType.CLICK) {
			google.maps.event.addListener(map, TriggerType.CLICK, () => {
				infoWindow.close();
			});
		} else {
			google.maps.event.addListener(marker, TriggerType.MOUSEOVER, () => {
				infoWindow.close();
			});
		}
	}

	return marker;
};

export const addGooglePolyline = ({
	map,
	polylineData
}: {
	map: google.maps.Map;
	polylineData: ExtendedPolylineData;
}) => {
	const polylinePath: google.maps.LatLng[] = polylineData.path.flatMap(p => {
		if (!isLatLngValid(p)) return [];

		const { lat, lng } = p;
		return [new google.maps.LatLng(lat, lng)];
	});

	const polylineOptions: google.maps.PolylineOptions = {
		strokeColor: polylineData?.color ?? '#3d8deb',
		strokeOpacity: polylineData?.strokeOpacity ?? 1.0,
		strokeWeight: polylineData?.strokeWeight ?? 5,
		path: polylinePath,
		visible: true
	};

	const generatedPolyline = new google.maps.Polyline(polylineOptions);

	if (polylineData.onMouseover) {
		google.maps.event.addListener(generatedPolyline, 'mouseover', () => {
			if (polylineData.onMouseover) {
				polylineData.onMouseover(generatedPolyline);
			}
		});
	}
	if (polylineData.onMouseout) {
		google.maps.event.addListener(generatedPolyline, 'mouseout', () => {
			if (polylineData.onMouseout) {
				polylineData.onMouseout(generatedPolyline);
			}
		});
	}

	generatedPolyline.setMap(map);
	return generatedPolyline;
};

export const addLocationToBounds = (location: LatLng, bounds: google.maps.LatLngBounds) => {
	const { lat, lng } = location;
	if (isNaN(lat) || isNaN(lng)) return;

	bounds.extend(new google.maps.LatLng(lat, lng));
};

export const removeEntityFromMap = (entity: google.maps.MVCObject) => {
	entity.set('map', null);
};

export const getDefaultIcon = (color: string, anchor: Anchor = defaultAnchor): google.maps.Symbol => {
	return {
		...defaultIcon,
		fillColor: color,
		anchor: convertIconAnchorToOffset(anchor, {
			width: DEFAULT_ICON_WIDTH,
			height: DEFAULT_ICON_HEIGHT
		}) as google.maps.Point
	};
};

export const getSelectedIcon = (color: string, anchor: Anchor = defaultAnchor): google.maps.Symbol => {
	return {
		...selectedIcon,
		fillColor: color,
		anchor: convertIconAnchorToOffset(anchor, {
			width: DEFAULT_ICON_WIDTH,
			height: DEFAULT_ICON_HEIGHT
		}) as google.maps.Point
	};
};

const getLabelPixelPositionOffset = (width: number, height: number) => ({
	x: width / 2,
	y: height
});

const defaultIcon = {
	labelOrigin: getLabelPixelPositionOffset(DEFAULT_ICON_WIDTH, DEFAULT_ICON_HEIGHT * 0.4),
	path: DEFAULT_ICON_SVG_PATH,
	strokeColor: 'rgba(0,0,0,0.32)',
	fillOpacity: 1,
	strokeWeight: 2,
	scale: DEFAULT_ICON_SCALE
} as google.maps.Symbol;

const selectedIcon = {
	labelOrigin: getLabelPixelPositionOffset(DEFAULT_ICON_WIDTH, DEFAULT_ICON_HEIGHT * 0.4),
	path: DEFAULT_ICON_SVG_PATH,
	strokeColor: 'rgb(0 0 0 / 72%)',
	fillOpacity: 1,
	strokeWeight: 2,
	scale: SELECTED_ICON_SCALE
} as google.maps.Symbol;

export const defaultText = {
	color: 'white',
	fontFamily: 'Inter',
	fontWeight: '500',
	fontSize: '13px'
} as google.maps.MarkerLabel;

export const convertIconAnchorToOffset = (anchor: Anchor, iconSize: Size): { x: number; y: number } => {
	const { width, height } = iconSize;

	switch (anchor) {
		case 'center':
			return {
				x: width / 2,
				y: height / 2
			};

		case 'top':
			return {
				x: width / 2,
				y: 0
			};

		case 'bottom':
			return {
				x: width / 2,
				y: height
			};

		case 'left':
			return {
				x: 0,
				y: height / 2
			};

		case 'right':
			return {
				x: width,
				y: height / 2
			};

		default:
			throw new Error(`Unsupported anchor ${anchor}`);
	}
};

export const setMapZoom = (map: google.maps.Map, zoom: number) => {
	// @ts-ignore
	map.systemZoomChange = true;
	map.setZoom(zoom);
};

export const setMapCenter = (map: google.maps.Map, center: google.maps.LatLng | BringgLocation) => {
	// @ts-ignore
	map.systemZoomChange = true;
	map.setCenter(center);
};

export const fitMapBound = (map: google.maps.Map, bounds: google.maps.LatLngBounds, padding?: number) => {
	// @ts-ignore
	map.systemZoomChange = true;
	google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
		// @ts-ignore
		map.systemZoomChange = true;
	});
	map.fitBounds(bounds, padding);
};

export const panMap = (map: google.maps.Map, pin: google.maps.LatLng) => {
	// @ts-ignore
	map.systemZoomChange = true;
	map.panTo(pin);
};

export const shouldDisplayTooltip = (selectedMarkers: IdType[] | undefined): boolean => {
	// Only display tooltip for single marker
	return selectedMarkers?.length === 1;
};

export const shouldDisplayTooltipForSelectedMarker = (
	selectedMarkers: IdType[] | undefined,
	markerId: IdType
): boolean => {
	// Only display tooltip for single marker
	return shouldDisplayTooltip(selectedMarkers) && selectedMarkers!.includes(markerId);
};
