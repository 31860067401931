import React from 'react';

import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { getRoot } from 'mobx-easy';
import { VehicleType, Vehicle, Skill, ParkingSpot, PrivilegeTypes } from '@bringg/types';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { BringgException } from '@bringg/dashboard-sdk/dist/Core/BringgException';
import { confirm } from '@bringg/react-components/dist/components/modal/modal';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import { BringgInput, Row } from '@bringg/react-components';

import { Select, Option, MultiSelect, FetchedData, Form, Button, FormChildren, Privilege } from '../../../components';
import bringgNotification from '../../../services/notification';
import Translate from '../../../translation/translator';
import { maxLength, requiredRule } from '../../../services/validation-rules';
import * as parkingSpotsProvider from '../../../services/parking-spots-provider';
import RootStore from '../../../stores/root-store';

interface Props {
	vehicle: Vehicle;
	onUpdate: (vehicle: Vehicle) => void;
}

interface State {
	editMode: boolean;
	vehicleTypeId: number;
	defaultParkingSpotId: number;
	vehicleTypes: VehicleType[];
	teamParkingSpots: ParkingSpot[];
	skills: Skill[];
	isFetched: boolean;
	trailerId: number;
	trailers: Vehicle[];
}

export default class VehicleCard extends React.Component<Props, State> {
	state = {
		editMode: false,
		vehicleTypeId: this.props.vehicle.vehicle_type_id,
		defaultParkingSpotId: this.props.vehicle.default_parking_spot_id,
		vehicleTypes: [],
		teamParkingSpots: [],
		skills: [],
		isFetched: false,
		trailerId: _get(this.props.vehicle, 'trailer.id'),
		trailers: []
	};

	async componentDidMount() {
		await this.loadCardData();
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.vehicle.id !== this.props.vehicle.id) {
			this.initState();
		}
	}

	// eslint-disable-next-line react/sort-comp
	enableEdit = (): void => {
		this.setState({ editMode: true });
	};

	resetState = (): void => {
		this.initState();
	};

	saveEdit = async (values: Partial<Vehicle>): Promise<void> => {
		try {
			const vehicle: Partial<Vehicle> = {
				...values,
				vehicle_type_id: this.state.vehicleTypeId,
				default_parking_spot_id: this.state.defaultParkingSpotId
			};
			const updatedVehicle = await getRoot<RootStore>().data.vehiclesStore.update(this.props.vehicle.id, vehicle);
			this.props.onUpdate(updatedVehicle);
		} catch (error) {
			bringgNotification.error((error as BringgException).message);
		} finally {
			this.setState({ editMode: false });
		}
	};

	typeChanged = (vehicleTypeId: number): void => {
		this.setState({ vehicleTypeId });
	};

	setDefaultParkingSpot = (defaultParkingSpotId: number): void => {
		this.setState({ defaultParkingSpotId });
	};

	trailerChanged = async (trailerId: number): Promise<void> => {
		try {
			await getRoot<RootStore>().data.vehiclesStore.update(trailerId, { truck_id: this.props.vehicle.id });
			this.setState({ trailerId });
		} catch (error) {
			bringgNotification.error((error as BringgException).message);
		}
	};

	deleteVehicle = async () => {
		const { vehicle } = this.props;

		try {
			await getRoot<RootStore>().data.vehiclesStore.delete(vehicle.id);
			window.location.replace(`${window.location.origin}/#/drivers/teams/${vehicle.team_id}`);
		} catch (error) {
			bringgNotification.error((error as BringgException).message);
		}
	};

	// eslint-disable-next-line react/sort-comp
	onDelete = () => {
		const { vehicle } = this.props;

		if (vehicle.user_id) {
			bringgNotification.warn(<Translate text="VEHICLE.CANT_DELETE_ASSIGNED_USER" />);
			return;
		}

		confirm({
			content: <Translate text="VEHICLE.CONFIRM_DELETE" />,
			onOk: this.deleteVehicle
		});
	};

	async loadCardData() {
		try {
			const [vehicleTypes, teamParkingSpots, vehicles, skills] = await Promise.all([
				getRootEnv().dashboardSdk.sdk.vehicleTypes.getAll(),
				parkingSpotsProvider.getDriverParkingSpots(this.props.vehicle.team_id),
				getRoot<RootStore>().data.vehiclesStore.loadAll(),
				getRootEnv().dashboardSdk.sdk.skills.getAll()
			]);

			const trailers: Vehicle[] = _filter(
				vehicles,
				(vehicle: Vehicle) => vehicle.team_id === this.props.vehicle.team_id && vehicle.is_trailer
			);

			this.setState({ vehicleTypes, skills, trailers, teamParkingSpots, isFetched: true });
		} catch (error) {
			bringgNotification.error((error as BringgException).message);
		}
	}

	initState() {
		this.setState({
			editMode: false,
			vehicleTypeId: this.props.vehicle.vehicle_type_id,
			defaultParkingSpotId: this.props.vehicle.default_parking_spot_id,
			trailerId: _get(this.props.vehicle, 'trailer.id')
		});
	}

	public render() {
		const { enableEdit, saveEdit, typeChanged, trailerChanged, setDefaultParkingSpot } = this;
		const { vehicle } = this.props;
		const {
			editMode,
			vehicleTypeId,
			defaultParkingSpotId,
			isFetched,
			vehicleTypes,
			skills,
			trailerId,
			trailers,
			teamParkingSpots
		} = this.state;
		const submitButton = editMode ? (
			<Button type="primary" htmlType="submit" className="save-button card-button">
				<Translate text="VEHICLE.SAVE" />
			</Button>
		) : null;
		const cancelButton = editMode ? (
			<Button className="cancel-button card-button" onClick={this.resetState}>
				<Translate text="VEHICLE.CANCEL" />
			</Button>
		) : null;

		return (
			<div className="vehicle-card">
				<Row className="vehicle-header">
					<span className="vehicle-name-header">{vehicle.name}</span>
					{!editMode ? (
						<Privilege privilegeName={PrivilegeTypes.VEHICLE_CREATE_AND_UPDATE}>
							<div className="vehicle-card-actions">
								<div className="delete-button card-button" onClick={this.onDelete}>
									<BringgIcon
										key="delete-icon"
										iconName={BringgFontIcons.Trash}
										className="delete-button-icon"
									/>
									<div key="delete">Delete</div>
								</div>
								<div className="edit-button card-button" onClick={enableEdit} role="button">
									<BringgIcon
										key="icon"
										iconName={BringgFontIcons.Pencil}
										className="edit-button-icon"
									/>
									<div key="edit">Edit</div>
								</div>
							</div>
						</Privilege>
					) : null}
				</Row>
				<Row className="license-plate-header">
					<span>{vehicle.license_plate}</span>
				</Row>
				<hr className="header-separated-line" />
				<Row className="vehicle-card-form-container">
					<FetchedData isFetched={isFetched}>
						<Form
							onSubmit={async value => saveEdit(value)}
							submitButton={submitButton}
							cancelButton={cancelButton}
							className="vehicle-card-form"
							initialValues={{ ...vehicle, trailer_id: trailerId }}
						>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.NAME" />,
									htmlFor: 'name',
									rules: [requiredRule, maxLength(50)],
									name: 'name'
								}}
								id="name"
							>
								<BringgInput disabled={!editMode} className="form-input" />
							</FormChildren>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.EXTERNAL_ID" />,
									name: 'external_id',
									htmlFor: 'external_id'
								}}
								id="external_id"
							>
								<BringgInput disabled={!editMode} className="form-input" />
							</FormChildren>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.LICENSE_PLATE" />,
									htmlFor: 'license_plate',
									name: 'license_plate',
									rules: [requiredRule, maxLength(20)]
								}}
								id="license_plate"
							>
								<BringgInput disabled={!editMode} className="form-input" />
							</FormChildren>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.MODEL" />,
									htmlFor: 'model',
									name: 'model'
								}}
								id="model"
							>
								<BringgInput disabled={!editMode} className="form-input" />
							</FormChildren>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.COLOR" />,
									htmlFor: 'color',
									name: 'color'
								}}
								id="color"
							>
								<BringgInput disabled={!editMode} className="form-input" type="text" />
							</FormChildren>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.TYPE" />,
									htmlFor: 'vehicle_type_id',
									name: 'vehicle_type_id'
								}}
								id="vehicle_type_id"
							>
								<Select
									disabled={!editMode}
									className="form-input"
									id="vehicle_type"
									value={vehicleTypeId}
									onSelect={typeChanged}
									showArrow={editMode}
								>
									{vehicleTypes.map(option => (
										<Option key={option.id.toString()} value={option.id}>
											{' '}
											{option.title}{' '}
										</Option>
									))}
								</Select>
							</FormChildren>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.SKILLS" />,
									htmlFor: 'skills',
									name: 'skills',
									rules: [{ required: false, type: 'array' }]
								}}
								id="skills"
							>
								<MultiSelect
									placeholder={<Translate text="VEHICLES.SKILLS_PLACEHOLDER" />}
									disabled={!editMode}
									showArrow={editMode}
									className="form-input"
									options={skills.map(skill => {
										return { children: skill.name, value: skill.name };
									})}
								/>
							</FormChildren>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.TRAILER_ID" />,
									htmlFor: 'trailer_id',
									name: 'trailer_id'
								}}
								id="trailer"
							>
								<Select
									disabled={!editMode}
									className="form-input trailers-select"
									id="trailer_id"
									value={trailerId}
									onSelect={async value => trailerChanged(value)}
									showArrow={editMode}
								>
									{trailers.map(option => (
										<Option key={option.id.toString()} value={option.id}>
											{option.name}
										</Option>
									))}
								</Select>
							</FormChildren>
							<FormChildren
								formItemsProps={{
									label: <Translate text="VEHICLES.DEFAULT_PARKING_SPOT" />,
									htmlFor: 'default_parking_spot_id',
									name: 'default_parking_spot_id'
								}}
								id="default_parking_spot_id"
							>
								<Select
									disabled={!editMode}
									className="form-input"
									id="default_parking_spot"
									value={defaultParkingSpotId}
									onSelect={setDefaultParkingSpot}
									showArrow={editMode}
								>
									{[
										<Option key={null} value={null}>
											<Translate text="VEHICLES.NO_DEFAULT_PARKING_SPOT" />
										</Option>
									].concat(
										teamParkingSpots.map(parkingSpot => (
											<Option key={parkingSpot.id.toString()} value={parkingSpot.id}>
												{parkingSpot.name}
											</Option>
										))
									)}
								</Select>
							</FormChildren>
						</Form>
					</FetchedData>
				</Row>
			</div>
		);
	}
}
