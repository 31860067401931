import { useMemo } from 'react';

import Team from 'bringg-web/stores/teams/domain-object/team';

export type TeamsTree = {
	label: string;
	value: number;
	children: TeamsTree[];
	disabled?: boolean;
	checkable?: boolean;
};

const createTeamsTree = (teams: Team[]): TeamsTree[] => {
	const hashTeamsTable = Object.create(null);
	teams.forEach(team => (hashTeamsTable[team.id] = { label: team.name, value: team.id, children: [] }));
	const teamsTree = [];
	teams.forEach(team => {
		if (team.parent_team_id && hashTeamsTable[team.parent_team_id] && team.id !== team.parent_team_id)
			hashTeamsTable[team.parent_team_id].children.push(hashTeamsTable[team.id]);
		else teamsTree.push(hashTeamsTable[team.id]);
	});
	return teamsTree;
};

const useTeamsTreeData = (fetchedTeams: Team[] = []) => useMemo(() => createTeamsTree(fetchedTeams), [fetchedTeams]);

export default useTeamsTreeData;
