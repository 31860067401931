// taken from https://googlemaps.github.io/js-markerclusterer/public/renderers/

import { Renderer, Cluster, ClusterStats } from '@googlemaps/markerclusterer';

const MARKER_SIZE = 70;

export default class ClusterRenderer implements Renderer {
	render({ markers, count, position, marker }: Cluster, stats: ClusterStats): google.maps.Marker {
		const markersArray = markers || [marker];
		let color;

		if (markersArray[0].icon) {
			const allSameColor = markersArray.every(
				m => m.icon && m.icon.fillColor === markersArray[0]?.icon.fillColor
			);
			color = allSameColor ? markersArray[0].icon.fillColor : '#718096';
		} else {
			color = count > Math.max(10, stats.clusters.markers.mean) ? '#c0392b' : '#2980b9';
		}

		const svg = window.btoa(`
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity=".8" r="70" />    
    </svg>`);

		return new google.maps.Marker({
			position,
			icon: {
				url: `data:image/svg+xml;base64,${svg}`,
				fillColor: color,
				scaledSize: new google.maps.Size(MARKER_SIZE, MARKER_SIZE),
				anchor: new google.maps.Point(MARKER_SIZE / 2, MARKER_SIZE / 2)
			},
			label: {
				text: String(count),
				color: 'rgba(255,255,255,0.9)',
				fontSize: '12px'
			},
			zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count
		});
	}
}
