/* eslint-disable angular/di-unused */
'use strict';

angular
	.module('bringgApp')
	.service(
		'LoadConstraintsService',
		function (
			$rootScope,
			BringgSDK,
			$uibModal,
			$q,
			VehicleTypesService,
			VehiclesService,
			UserType,
			Tasks,
			Employees,
			TranslationService,
			MerchantConfigurations,
			Authentication,
			BringgCommonUtils,
			MaxLoadCalculationIndicator,
			TASK_DEFAULT_TYPES
		) {
			const _this = this;
			_this.getVehicleTotalWeight = getVehicleTotalWeight;
			_this.prepareLoadConstraintHtml = prepareLoadConstraintHtml;
			_this.getWeightAndTotalWeight = getWeightAndTotalWeight;
			_this.getHandlingUnits = getHandlingUnits;
			_this.getHandlingUnitsHtml = getHandlingUnitsHtml;
			_this.getUserType = getUserType;
			_this.calcMaxRouteValue = calcMaxRouteValue;
			_this.calcTaskInventoriesTotalQuantity = calcTaskInventoriesTotalQuantity;

			function getVehicleTotalWeight(run, driver, tasks, vehicleTypeByVehicleId) {
				const vehicleIds = getVehicleIds(run, driver, tasks);
				let vehicleTotalWeight = 0;

				vehicleIds.forEach(function (vehicleId) {
					vehicleTotalWeight += _.get(vehicleTypeByVehicleId[vehicleId], 'max_total_weight', 0);
				});

				return vehicleTotalWeight;
			}

			function getVehicleIds(run, driver, tasks) {
				let vehicleId;
				if (run) {
					vehicleId = run.vehicle_id;
				} else if (driver) {
					vehicleId = driver.vehicle_id;
				}
				if (!vehicleId) {
					return [];
				}
				const vehicleIds = [vehicleId];

				tasks.forEach(function (task) {
					vehicleIds.push(task.vehicle_id);
				});

				return _.chain(vehicleIds).uniq().compact().value();
			}

			function buildDynamicCapacityHint(weightData) {
				let dynamicCapacityHint = '';
				if (weightData.currentLoad != null) {
					dynamicCapacityHint += `${TranslationService.instant(
						'DYNAMIC_CAPACITY.CURRENT_LOAD_TEXT'
					).replaceAll(' ', '&nbsp;')}:&nbsp;${weightData.currentLoad}`;
				}
				if (weightData.expectedPickup != null) {
					if (dynamicCapacityHint.length > 0) {
						dynamicCapacityHint += '&#013;';
					}
					dynamicCapacityHint += `${TranslationService.instant(
						'DYNAMIC_CAPACITY.EXPECTED_PICKUP_TEXT'
					).replaceAll(' ', '&nbsp;')}:&nbsp;${weightData.expectedPickup}`;
				}
				return dynamicCapacityHint;
			}

			function prepareLoadConstraintHtml(weightData) {
				if (!weightData) {
					return '';
				}

				let dynamicCapacityHint = buildDynamicCapacityHint(weightData);

				let html = '<div class="icon-container weight"><span class="table-icon max-weight-icon"> </span></div>';
				html += `<span class="count">` + weightData.value.toLocaleString('en-US') + '</span>';
				html += '<span class="border">/</span>';
				html +=
					`<span class="total-count ${weightData.value > weightData.maxValue ? 'warning' : ''}">` +
					weightData.maxValue.toLocaleString('en-US') +
					'</span>';

				html = `<span class="details-container details-text" title=${dynamicCapacityHint}>` + html + '</span>';

				return html;
			}

			function getCustomUnitsHtml(weightData) {
				if (!weightData) {
					return '';
				}

				let dynamicCapacityHint = buildDynamicCapacityHint(weightData);

				return (
					`<span class="custom-units-details" title=${dynamicCapacityHint}>` +
					TranslationService.instant(weightData.name) +
					' ' +
					weightData.value.toLocaleString('en-US') +
					'/' +
					`<span class="${weightData.value > weightData.maxValue ? 'warning' : ''}">` +
					weightData.maxValue.toLocaleString('en-US') +
					'</span></span>'
				);
			}

			function getHandlingUnits(data, tasks, params, run) {
				const handlingUnits = MerchantConfigurations.custom_units_definition || {};
				const handlingUnitsToView = [];

				const vehicleType = data.vehicleTypeByVehicleId[_.get(run, 'vehicle_id')];
				let trailerType = data.vehicleTypeByVehicleId[_.get(tasks[0], 'vehicle_id')];

				if (!_.get(trailerType, 'is_trailer')) {
					trailerType = undefined;
				}

				const vehicleHandlingUnits = _.get(vehicleType, 'handling_units', undefined);
				const trailerHandlingUnits = _.get(trailerType, 'handling_units', undefined);

				for (const unitName in handlingUnits) {
					if (vehicleHandlingUnits) {
						const handlingUnitPath = `handling_units.${unitName}`;
						let handlingUnitCapacity = _.get(run, `handling_units_capacity.${unitName}`);
						if (!_.isNumber(handlingUnitCapacity)) {
							handlingUnitCapacity =
								_.get(vehicleHandlingUnits, unitName, 0) + _.get(trailerHandlingUnits, unitName, 0);
						}
						handlingUnitsToView.push(
							getRouteAggregationValues(
								tasks,
								params,
								run,
								handlingUnitPath,
								handlingUnitCapacity,
								handlingUnits[unitName].name
							)
						);
					}
				}

				return handlingUnitsToView;
			}

			function getHandlingUnitsHtml(data, tasks, params, run, driver) {
				const handlingUnits = MerchantConfigurations.enable_vehicles
					? getHandlingUnits(data, tasks, params, run)
					: [];
				const weightUnit = getWeightAndTotalWeight(data, tasks, params, run, driver);

				return [weightUnit, ...handlingUnits]
					.map(item => {
						return item?.name === 'PLANNING.TOTAL_WEIGHT'
							? prepareLoadConstraintHtml(item)
							: getCustomUnitsHtml(item);
					})
					.join('');
			}

			function getRouteAggregationValues(tasks, params, run, unitName, unitMaxValue, unitNameTranslate) {
				let maxRunValue = _.get(run, `max_${unitName}`);
				if (!_.isNumber(maxRunValue)) {
					maxRunValue = _this.calcMaxRouteValue(
						tasks,
						params.isClusterModeEnabled,
						params.doNotMultipleWeightByQuantity,
						unitName
					);
				}
				const value = Math.round(maxRunValue * 100) / 100;
				const dynamicCapacityValues = getDynamicCapacityValues(run, params, unitName);

				return Object.assign(
					{
						value,
						maxValue: unitMaxValue,
						name: unitNameTranslate
					},
					dynamicCapacityValues
				);
			}

			function getWeightAndTotalWeight(data, tasks, params, run, driver) {
				const userType = getUserType(run, tasks, data.plannedRoutes, data.userTypes, data.employees);
				let maxTotalWeightAllowed = run && run.weight_capacity;

				if (!_.isNumber(maxTotalWeightAllowed)) {
					if (MerchantConfigurations.enable_vehicles) {
						maxTotalWeightAllowed = getVehicleTotalWeight(run, driver, tasks, data.vehicleTypeByVehicleId);
					}
					maxTotalWeightAllowed = maxTotalWeightAllowed || _.get(userType, 'max_total_weight');
				}

				if (!maxTotalWeightAllowed || !validateDriverTaskInventories(tasks)) {
					return;
				}

				return getRouteAggregationValues(
					tasks,
					params,
					run,
					'weight',
					maxTotalWeightAllowed,
					'PLANNING.TOTAL_WEIGHT'
				);
			}

			function getDynamicCapacityValue(run, unitName, field) {
				const fieldPostfix = unitName.split('.');
				fieldPostfix.splice(1, 0, field);
				const value = _.get(run, `dynamic_aggregated_${fieldPostfix.join('.')}`);
				if (!_.isNumber(value)) {
					return;
				}
				return Math.round(value * 100) / 100;
			}

			function shouldDisplayDynamicCurrentLoad(dynamicCapacityMode) {
				return dynamicCapacityMode === 1 || dynamicCapacityMode === 2;
			}

			function shouldDisplayDynamicExpectedPickup(dynamicCapacityMode) {
				return dynamicCapacityMode === 1 || dynamicCapacityMode === 3;
			}

			function getDynamicCapacityValues(run, params, unitName) {
				const dynamicCapacityMode = params.dynamicCapacityMode;
				const result = {};
				if (shouldDisplayDynamicCurrentLoad(dynamicCapacityMode)) {
					result.currentLoad = getDynamicCapacityValue(run, unitName, 'current_load');
				}
				if (shouldDisplayDynamicExpectedPickup(dynamicCapacityMode)) {
					result.expectedPickup = getDynamicCapacityValue(run, unitName, 'expected_pickup');
				}
				return result;
			}

			function validateDriverTaskInventories(tasks) {
				return (
					_.isArray(tasks) &&
					_.every(tasks, function (task) {
						return _.isArray(task.task_inventories);
					})
				);
			}

			function getUserType(run, tasks, plannedRoutes, userTypes, employees) {
				const firstTask = tasks[0];
				const rowRouteName = _.get(firstTask, 'route_name');

				let userType;

				if (_.get(firstTask, 'user')) {
					userType = _.get(firstTask, 'user.userType');
				} else if (rowRouteName && run) {
					const plannedRoute = _.chain(plannedRoutes)
						.filter(function (plannedRoute) {
							return _.get(plannedRoute, 'title') === rowRouteName;
						})
						.first()
						.value();
					userType = _.find(userTypes, { id: _.get(plannedRoute, 'user_type_id') });
				} else if (run && run.user_id) {
					const user = Employees.getEmployeeIfCached(run.user_id) || _.find(employees, { id: run.user_id });
					userType = _.find(userTypes, { id: _.get(user, 'user_type_id') });
				}

				return userType;
			}

			function shouldUseOldTotalQuantityCalculation() {
				return Authentication.currentUser().feature_flags.use_old_total_quantity_calculation || false;
			}

			function calcTaskInventoriesTotalQuantity(task) {
				if (shouldUseOldTotalQuantityCalculation()) {
					return calcMaxLoadOnTaskInventories(task.task_inventories, true, 'original_quantity');
				}
				let value;
				if (task.aggregations) {
					value = task.aggregations.inventory_total_quantity;
				}
				if (_.isNil(value)) {
					value = BringgCommonUtils.calculateTaskInventoriesQuantity(
						task,
						MerchantConfigurations.unique_inventory_fields
					);
				}
				return value;
			}

			function calcMaxRouteValue(tasks, isClusterMode, ignoreQuantity, field) {
				return BringgCommonUtils.calculateTasksMaxLoad({
					items: tasks,
					field,
					ignoreQuantity,
					isClusterMode,
					uniqueInventoryFields: MerchantConfigurations.unique_inventory_fields
				});
			}

			function calcMaxLoadOnTaskInventories(taskInventories, ignoreQuantity, field) {
				let taskInventoryToLoadValue = createTaskInventoryToLoadValueMapping(
					taskInventories,
					ignoreQuantity,
					field,
					MaxLoadCalculationIndicator.shouldCalcLoadRecursively()
				);

				let initialLoad = aggregateInitialLoadField(taskInventories, taskInventoryToLoadValue, ignoreQuantity);
				return aggregateRouteLoadField(taskInventories, taskInventoryToLoadValue, initialLoad);
			}

			function createTaskInventoryToLoadValueMapping(
				taskInventories,
				ignoreQuantity,
				field,
				useRecursiveLoadCalc
			) {
				return taskInventories.reduce((mapping, taskInventory) => {
					mapping[taskInventory.id] = calcTaskInventoryLoad(
						taskInventory,
						ignoreQuantity,
						field,
						useRecursiveLoadCalc
					);
					return mapping;
				}, {});
			}

			function calcTaskInventoryLoad(taskInventory, ignoreQuantity, field, useRecursiveLoadCalc) {
				let value = _.get(taskInventory, field, 0);
				if (useRecursiveLoadCalc) {
					value = (taskInventory.inventories || []).reduce(
						(acc, childInventory) =>
							acc + calcTaskInventoryLoad(childInventory, ignoreQuantity, field, useRecursiveLoadCalc),
						value
					);
				}
				return ignoreQuantity ? value : taskInventory.original_quantity * value;
			}

			function aggregateInitialLoadField(taskInventories, taskInventoryToLoadValue, ignoreQuantity) {
				let visitPickup = false;
				const pickedUpQuantities = {};
				return _.reduce(
					taskInventories,
					function (value, taskInventory) {
						const inventory = taskInventory.inventory;
						visitPickup = visitPickup || taskInventory.pending;

						if (inventory) {
							if (taskInventory.pending) {
								// Pickup task
								const inventoryId = inventory.id;
								const oldQuantity = pickedUpQuantities[inventoryId] || 0;
								pickedUpQuantities[inventoryId] = oldQuantity + taskInventory.original_quantity;
							} else {
								// Drop-off task
								value += calcTaskInventoryInitialLoadField(
									pickedUpQuantities,
									taskInventory,
									taskInventoryToLoadValue,
									ignoreQuantity
								);
							}
						} else {
							if (!visitPickup) {
								value += taskInventoryToLoadValue[taskInventory.id] || 0;
							}
						}
						return value;
					},
					0
				);
			}

			function calcTaskInventoryInitialLoadField(
				pickedUpQuantities,
				taskInventory,
				taskInventoryToLoadValue,
				ignoreQuantity
			) {
				const inventoryId = taskInventory.inventory.id;
				let value = 0;
				const currentValue = taskInventoryToLoadValue[taskInventory.id] || 0;

				// Drop-off task not related to a pickup
				if (!pickedUpQuantities[inventoryId]) {
					return currentValue;
				}

				// Drop-off task connected to a pickup task (same inventory id)
				pickedUpQuantities[inventoryId] -= taskInventory.original_quantity;

				if (pickedUpQuantities[inventoryId] < 0) {
					const positiveQuantity = -1 * pickedUpQuantities[inventoryId];
					// currentValue already multiplied by original_quantity so we need to adjust it to get the right amount
					value = ignoreQuantity
						? currentValue
						: (positiveQuantity / taskInventory.original_quantity) * currentValue;
					pickedUpQuantities[inventoryId] = 0;
				}

				return value;
			}

			function aggregateRouteLoadField(taskInventories, taskInventoryToLoadValue, maxValue) {
				let currentMaxValue = maxValue;

				return _.reduce(
					taskInventories,
					function (value, taskInventory) {
						const currentValue = taskInventoryToLoadValue[taskInventory.id] || 0;
						currentMaxValue += (taskInventory.pending ? 1 : -1) * currentValue;

						if (value < currentMaxValue) {
							value = currentMaxValue;
						}
						return value;
					},
					maxValue
				);
			}
		}
	);
